import type { AjaxPlugin, AjaxResponse } from 'nice-axios'
import { ContentTypeEnum } from 'nice-axios'
import { aesDecrypt, aesEncrypt } from '~/utils/encrypt'
import { isProdMode } from '~/utils/env'

export const aesEncryptPlugin: AjaxPlugin = async (next, config) => {
  if (typeof config.meta?.aes !== 'boolean') {
    if (!config.meta)
      config.meta = {}
    config.meta.aes = true
  }
  const { openAesEncrypt } = useGlobSetting()
  if (config.meta?.aes && openAesEncrypt) {
    // 加密
    const securityStore = useSecurityStore()
    if (!securityStore.serverAesKey) {
      await securityStore.getServerAesKey()
      consola.log('securityStore.serverAesKey: ', securityStore.serverAesKey)
    }
    if (config.headers)
      config.headers['REQUEST-HEADER-ENCRYPT-AES-KEY-ID'] = securityStore.serverAesKeyId

    else
      config.headers = { 'REQUEST-HEADER-ENCRYPT-AES-KEY-ID': securityStore.serverAesKeyId }

    config.headers['Content-Type'] = ContentTypeEnum.JSON
    // const start = performance.now();
    config.data = aesEncrypt(JSON.stringify(config.data), securityStore.serverAesKey) || undefined
    // const end = performance.now();
    // console.log('加密耗时/ms', end - start);
  }

  return next()
}

export const aesDecryptPlugin: AjaxPlugin = async (next, config) => {
  const listener = (v: AjaxResponse) => {
    const { openAesEncrypt } = useGlobSetting()
    if (config.meta?.aes && openAesEncrypt && (v.status === 200 || v.status === 400)) {
      const securityStore = useSecurityStore()
      if (!config.meta.allReturn) {
        // const start = performance.now();
        v.data = JSON.parse(aesDecrypt(v.data as string, securityStore.serverAesKey))
        // const end = performance.now();
        if (!isProdMode()) {
          // console.log('解密耗时/ms', end - start);
          const req = JSON.parse(aesDecrypt(config.data as string, securityStore.serverAesKey) || '{}')
          consola.info({ req, url: config.url, resp: v.data })
        }
      }
    }
    return v
  }

  const errorListener = (v: any) => {
    listener(v.response)
    return Promise.reject(v)
  }
  return next().then(listener, errorListener)
}
