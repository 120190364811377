<script lang="ts" setup>
import { createNamespace } from '~/utils/bem'
import type { NwConfirmDialogOption } from '~/types'

const [name] = createNamespace('confirm-dialog-provider')
const messageRef = ref()
const show = ref(false)
const _option = ref<NwConfirmDialogOption>()

const create = (option: NwConfirmDialogOption) => {
  show.value = option.show
  _option.value = option
}

const close = () => {
  show.value = false
}

// vue3 无法从内部获取自身实例 妥协办法
const instance = {
  create,
  close,
}

onMounted(() => {
  setupNwConfirmDialogIns(instance)
})

defineExpose({ create, close })
</script>

<template>
  <div :class="[name]">
    <NwConfirmDialog ref="messageRef" v-bind="_option" v-model:show="show" />
  </div>
</template>

<style lang="less" scoped>
    .confirm-dialog-provider{

    }
</style>
