import type { NavigationGuardWithThis, Router } from 'vue-router'
import { RoutePath } from '~/enums/path'
import { VerificationStatusEnum } from '~/enums/swagger'

/**
 * 判断是否已经验证过邮箱
 * 1. 如果已经验证过则不得再次进入验证页面
 * 2. 如果还未验证需要强制引导至验证页面
 * @param router
 */
export const setupOtpGuard = (router: Router) => {
  router.beforeEach((to, from, next) => {
    const userStore = useUserStore()

    if (userStore.token && to.path !== RoutePath.Validate) {
      if (userStore.userInfo?.emailVerificationStatus === VerificationStatusEnum.NO_VERIFICATION) {
        next(RoutePath.Validate)
        return
      }
    }
    next()
  })
}

// 进入validate页面路由守卫，防止不在审核状态时进入
export const enterValidateGuard: NavigationGuardWithThis<undefined> = (to, form, next) => {
  const userStore = useUserStore()
  if (userStore.userInfo?.emailVerificationStatus === VerificationStatusEnum.PASS_VERIFICATION) {
    next(RoutePath.Base)
    return
  }
  next()
}
