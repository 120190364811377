import type { Router } from 'vue-router'
import { createEncryptGuard } from './encryptGuard'
import { setupOrgGuard } from './orgGuard'
import { setupOtpGuard } from './otpGuard'
import { setupPermissionGuard } from './permissionGuard'
import { setupRedirectGuard } from './redirectGuard'

export const setupGuard = (router: Router) => {
  createEncryptGuard(router)
  setupPermissionGuard(router)
  setupOtpGuard(router)
  setupOrgGuard(router)
  setupRedirectGuard(router)
}
