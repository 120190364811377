accounting:
  sync:
    confirm_company:
      desc: Please confirm the company name you’ve chosen to connect is correct.
      title: Confirm your company
    error:
      desc: You can retry to connect {name}.
      retry_btn: Retry
      title: Something goes wrong
    loading:
      desc: we are connecting your Pinvo with {name}
      title: Connecting to {name}
    match:
      add_new: Add a new payment account in {name}
      continue_btn: Continue
      error:
        archive: this name refers to an archived account in {name}, change one
        default: >-
          There is no matching account, please manually select or create a new
          account
        non_bank: this name refers to an non-bank account in {name}, change one
        repeat_local: cannot choose one {name} account for several Pinvo payment accounts
      left_title: Payment account in Pinvo
      match_desc: |
        Select the matching payment accounts in your {name} to link them.
        We have pre-matched some accounts, please check them carefully.
      no_match_desc: Select the matching payment accounts in your {name} to link them.
      right_title: Payment account in {name}
      title: Matching your accounts to {name}
address-line-1: Address line 1
address-line-2: Address line 2
and: and
apply-reset:
  action-btn: Send reset link
  go-back: Go back
  success: >-
    We have sent a password reset link to your email. If you don't have it,
    please check if it is in spam or retry in a few minutes.
  tip: Aha, you remember it now?
  title: Reset password
batch:
  action:
    cancel: Cancel
    send: Send payment request
  item:
    amount: Amount
    email: Payer’s email
    name: Customer name
  note:
    placeholder: e.g. Commission for Jan
    save: Save and close
    title: Note to payer
  title:
    - Send a payment request
    - Send {number} payment requests
bill:
  create:
    button:
      save: Save for later
      schedule: Continue to schedule a payment
    form:
      invoice_no:
        placeholder: e.g. 00254396
        title: Invoice No.
      note:
        placeholder: Help to refresh the memory of whom you are paying
        title: Note to Vendor
      payee_account:
        account_no:
          label: Account No.
          placeholder: What is the account number?
        sort_code:
          label: Sort Code
          placeholder: What is the sort code?
        title: Vendor account
      payment_amount:
        title: Payment amount
      total_amount:
        label: Payment amount
      vendor_info:
        title: Vendor information
        vendor_email:
          label: Vendor Email
          placeholder: The vendor will be notified of payment via email
        vendor_name:
          label: Vendor Name
          placeholder: Who are you paying?
  detail:
    action:
      - Schedule payment
      - Review bill
    amount: Amount
    close: Close
    date-create: Date created
    delete: Delete the bill record
    download: Download the invoice pdf of the bill
    edit: Edit the bill information
    file:
      download: Download
    link: Copy the bill link
    notes: Notes
    review:
      desc: >-
        This bill was sent directly to your Pay Inbox. Review bill details
        before you schedule a payment.
      title: Review bill details
    review-bill: Review bill
    timeline: Timeline
    timeline-operation:
      create: Bill created
      mark-as-paid: Bill marked as paid
      mark-as-unpaid: Bill marked as unpaid
      paid: Bill paid
      received: Bill received
      schedule: Payment scheduled
      support: Bill supposed to be paid
      updated-by-vendor: Bill updated by vendor
    view-file: View File
  filter:
    dropdown:
      captured: Captured from emails
      clear-all: Clear all
      need-to-review: Need to review
      reviewed: Reviewed
  index:
    dropdown:
      create: Enter manually
      upload:
        subTitle: ( pdf/jpg/png )
        title: Upload files
  ocr:
    delete: Delete image/file
    empty: |
      All uploaded invoices have been processed.
      You can upload more bills if needed!
    preview:
      delete:
        confirm: Yes, delete it
        title: Are you sure you want to delete invoice?
      error: The file is upload failed
      load: The file is uploading
    upload_btn: Drag or select files to upload
button:
  about: About
  back: Back
  cancel: Cancel
  exit: Log out
  go: GO
  home: Home
  save: Save
  test: test
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
  yes: Yes
common:
  you_can_finish:
    actions:
      - finish
    message: You can {action0}
company_address: Company address
company_industry: Industry
company_name: Business name
company_number: Company number
company_type: Company type
confirm_pwd: Confirm password
contact:
  create:
    email:
      placeholder: Get the correct email address of payer
      title: Email
    name:
      placeholder: Who is paying you?
      title: Name
contacts:
  action-btn: Action
  action-delete: Delete contact
  action-edit: Edit contact
  action-create-invoice: Create invoice
  add-btn: Add
  address: Company address
  address-line-1-placeholder: Address line 1
  address-line-2-placeholder: Address line 2
  check-customer:
    - Add a customer
    - Enter a customer’s details
  check-vendor:
    - Add a vendor
    - Enter a vendor’s details
  city-placeholder: City/Town
  code-placeholder: Postcode
  company-name: Company name
  create-btn: New
  create-title: Add a new contact
  create-type-error: Choose the contact type first,please
  customers: Customers
  delete-confirm: Yes, delete it
  delete-title: Are you sure about deleting this contact?
  detail-title: Contact details
  edit-title: Edit contact
  email: Email address
  email-placeholder: What's the email address?
  empty:
    customer:
      subtitle: You can add them one by one.
      title: Add your customers to send faster payment requests
    vendor:
      subtitle: You can add them one by one.
      title: Add your vendors to make faster payments
  guide-btn: Add a contact
  guide-subtitle: Add your new contact as a vendor, customer or both.
  guide-title: What type of contact would you like to add?
  information-title: Contact payment information
  name-placeholder: What's the company name?
  option-tip: (optional)
  phone: Phone number
  phone-placeholder: What's the phone number?
  save-btn: Save
  state-placeholder: Country
  title: Contacts
  vendors: Vendors
country: Country of incorporation
current_pwd: Current password
dashboard: Dashboard
date: Date of incorporation
delete: Delete
detail: Detail
dialog:
  send_invoice:
    button: Send payment request
    discard_changes: Discard changes
    form:
      email:
        placeholder: Get the correct email address of customer
        title: Customer email
        error: The email format is incorrect.
      note:
        placeholder: e.g. Commission for Jan
        title: Note to customer
    notify_my_customer: Notify the customer
    subtitle: >-
      You’ve sent the payment request to {name}. We’ll keep the payer and you
      updated through emails.
    title:
      - Send a payment request
      - The payer will be notified
email: Email
export: Export
export-tip: Exporting invoices
first_name: First name
forgot_password: Forgot password?
get_paid:
  all_empty:
    button: Start getting paid
    desc: Link your bank account, and start getting paid on autopilot.
    dropdown:
      entry: Create an invoice with ease
    overdue: >-
      Nothing overdue here! Stay ontop of what’s owed with a overview of all
      your overdue invoices.
    paid: >-
      Never miss a payout with a simple, real-time overview of all your
      collected payments.
    sent: >-
      Keep an eye on your outstanding payment requests so you’re always on top
      of your cash flow.
    title: Ready to take control of your finances and get paid faster?
    unsent: Let’s start collecting those payments!
  confirm_dialog:
    cancel:
      cancel: No, close
      confirm: Yes, cancel request
      message: >-
        This payment request has been sent to {name}. Are you sure you want to
        cancel it?
      title: Cancel request?
    delete:
      cancel: No, close
      confirm:
        - Yes, delete
        - Yes, delete requests
      message:
        - |
          Are you sure you want to delete this invoice?
          This cannot be undone
        - >
          You’ve already sent out these payment requests. Are you sure you want
          to delete them?

          This cannot be undone
      title-multiple: Delete these {number} invoices?
      title-single: Delete this invoice?
    mark_as_paid:
      confirm: Yes, mark as paid
      message:
        - >
          Are you sure you want to mark this {price} payment request to {name}
          as paid?

          This action will move this payment request to your Paid list.

          We will also notify {name} of the change.
        - >
          Are you sure you want to mark this {price} payment request to {name}
          as paid?

          This action will move this payment request to your Paid list.
      success: Status changed to Paid
      title: Mark this payment request as paid?
    mark_as_unpaid:
      confirm: Yes, mark as unpaid
      message:
        - >
          Are you sure you want to change the status of this {price} payment
          request to {name} to unpaid?

          This action will move this payment request to your Unsent list.

          We will also notify {name} of the change.
        - >
          Are you sure you want to change the status of this {price} payment
          request to {name} to unpaid?

          This action will move this payment request to your Unsent list.
      success: Status changed to unpaid
      title: Change payment status to Unpaid?
intro:
  aka: Also known as
  desc: Opinionated Vite Starter Template
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  test:
    aa: dd
  whats-your-name: What's your name?
invoice:
  create:
    left:
      dialog:
        update:
          cancel: Discard changes
          confirm: Notify the payer
          message: >-
            You’ve sent the payment request to {email}. We’ll keep the payer and
            you updated through emails.
          title: The payer will be notified
      form:
        customer:
          add_button: Add a new payer
          placeholder: Who are you invoicing?
          title: Customer name
        address:
          title: Customer address
        due_date:
          placeholder: Choose a date
          title: Due date
        issue_date:
          placeholder: Choose a date
          title: Issued date
        invoice_no:
          placeholder: e.g. 00254396
          title: Invoice no.
        currency:
          placeholder: Choose a currency
          title: Currency
        bank_account:
          placeholder: Choose a bank account
          title: Bank Account
        item_detail:
          add_item: Add item
          amount: Net Amount
          item_tax:
            error: tax rate must be must be a value not more than 100
            placeholder: Choose a tax rate or customize input
            title: Item tax
          price: Unit price
          qty: QTY
          subtitle: Description
          title: Item details
        notes:
          placeholder: Help to refresh the memory of whom you are paying
          title: Note to payer
      resend_btn: Resend
      save_button: Save and close
      unsend_btn: Send payment request
    right:
      title: Invoice preview
  detail:
    amount: Amount
    close: Close
    customer-name: 'Invoice to:'
    date-created: Date created
    date-issued: Date issued
    date-overdue: Due date
    delete: Delete the invoice record
    desc: Description
    download: Download the invoice pdf
    download-success: Download invoice success
    edit: Edit the invoice information
    items: Items
    link: Copy the invoice link
    link-success: Link copied to clipboard
    notes: Notes
    price: Unit price
    qty: QTY
    resend: Resend request
    send: Send payment request
    source: from {customer} invoice
    subtotal: Subtotal
    timeline: Timeline
    timeline-operation:
      create: Invoice created
      date: Time stamp
      email-opened: Payment request email checked
      marked-paid: Invoice marked as paid
      note-to-customer: Note to customer
      page-viewed: Payment request page viewed
      paid: Invoice  paid
      payment-sent: Payment request sent
      reminder: Payment reminder
      scheduled: Invoice scheduled
      send-to-email: Send to
      unpaid: Change to unpaid
      update: Payment request updated
    total: Net Total
    total-vat: Total VAT 20%
  preview:
    accept-btn: Schedule payment
    date: Due Date
    from: 'From: {email}'
    invoice: Invoice
    invoice-from: New invoice from {company}
    invoice-num: Invoice No. {id}
    note: Note From Payee
    phone: 'Phone number: {phone}'
    qa:
      action: '{email}'
      message: >-
        If you have questions about this payment request, please email
        {action}.
    send-by-pinvo: is requesting a payment of
    support:
      action: service{'@'}pinvo.io
      message: Any questions? Please contact {action}
    tip:
      action: Try it now for free
      message: >-
        Pinvo is a payment automation platform tailored for SMBs in the UK.
        {action}.
    view: View
invoice_pdf:
  payer_name: Customer name
  payment_info:
    account_number: Account Number
    bank: Bank
    desc: 'Payments can also be made manually via bank transfer to:'
    note:
      actions:
        - Pinvo
        - here
      message: >-
        This invoice is generated with {action0}. Try it {action1} or contact us at {email}.
    payee_name: Account Name
    sort_code: Sort Code
    sub_title:
      action: payment link
      message: Pay online instantly via this {action}.
    title: Payment Info
kyb:
  step1:
    btn: Continue
    error: wait for uploading image
    tip: Skip for now. I’ll do it later
    title: Upload your company logo for more recognisable invoices
  step2:
    bank-account:
      account-name: Account name
      account-name-placeholder: What’s the name of the account your customers will pay into?
      account-number: Account no.
      account-number-placeholder: What's the account number?
      account-number-rule: should be a 8 digit number
      sort-code: Sort code
      sort-code-placeholder: What's the sort code?
      sort-code-rule: should be a 6 digit number
      title: Bank account
    btn: Continue
    company-info:
      crn: Company (registration) number
      crn-placeholder: What's your company number?
      crn-rule: should be 8 digit letter or number
      crn-tip: We’ll use this info for verification only.
      title: Company's legal info
      vat_no:
        title: VAT no.
        placeholder: What's the VAT number?
    title: Enter your company’s bank details and legal info to get paid
  step3:
    btn: Start
    tip: Let’s get you paid!
    title: You’re all set!
last_name: Last name
login:
  agree: By signing in, you agree to Pinvo's
  policy:
    actions:
      - Terms of service
      - Privacy policy
    message: By signing up, you agree to Pinvo's {action0} and {action1}.
  register:
    last_name: Last name (optional)
    tip: Already have a Pinvo account?
    title: Sign up
  remember_my_email: Remember me
  sign_up: Sign up
  terms: Terms of service
  tip: Don’t have a Pinvo account?
  title: Sign in to Pinvo
mark: Mark as paid
nature: Nature of business
new: New
new_pwd: New password
not-found: Not found
official_mail: pinvo{'@'}normal.works
optional: optional
org:
  create:
    form:
      button: Get started
      legal_company_name:
        title: Business legal name
        placeholder: What's the official registration name?
      trading_company_name:
        title: Business trading name
        placeholder: What's the doing business as name of your business?
      company_name:
        placeholder: What's the doing business as name of your business?
        title: Business name
      company_industry:
        placeholder: Which industry does your business operate in?
        title: Industry
    title: Tell us a bit about your business
otp:
  check: Check your spam folder or
  enter: Enter the code below
  invalid: The code does not match or it's expired
  not_received: Still haven’t received a code?
  resend_code: Resend code
  tip: For security reasons, we sent a 4-digit code to
  verify: Verify it’s you
password: Password
pay:
  all_empty:
    button: Add bills
    desc: Upload your invoices and schedule payments to keep your cash flow flowing
    paid: Upload your invoices and schedule payments to keep your cash flow flowing
    title:
      inbox: Ready to tackle that to-pay list?
      overdue: >-
        Stay on top of what you owe with a clear view of all your overdue
        invoices
      paid: >-
        Keep track of cash out with a real-time overview of all your sent
        payments.
      scheduled: Stay ahead with an overview of your scheduled payments.
  confirm_dialog:
    delete:
      confirm:
        - Yes, delete this bill
        - Yes, delete
      message:
        - Are you sure you want to delete this bill? This cannot be undone.
        - Are you sure you want to delete these bills? This cannot be undone.
      operation-error: >-
        Bill is managed by the payee side. Please contact the payee if any
        change is required.
      title-multiple: Delete {number} bills?
      title-single: Delete bill {number}(Invoice No.) from {name}?
    mark_as_paid:
      confirm: Yes
      message:
        - Are you sure to mark these bills Paid?
        - Are you sure to mark this {price} bill to {name} Paid?
      title:
        - Mark bills Paid?
        - Mark this bill Paid?
    mark_as_unpaid:
      confirm: Yes
      message:
        - Are you sure to mark these bills Unpaid?
        - Are you sure to mark this {price} bill to {name} Unpaid?
      title:
        - Mark bills Unpaid?
        - Mark this bill Unpaid?
  search:
    empty:
      desc: >-
        Try searching for something else, like an invoice number, vendor name,
        or due date.
      title: No results found
payment:
  authenticate:
    about_the_access:
      data_sharing:
        message: >-
          Yapily Connect will retrieve your bank data based on your request and
          provide this information to Pinvo.
        title: Data Sharing
      fca:
        message: >-
          Yapily Connect Ltd is authorised and regulated by the Financial
          Conduct Authority under the Payment Service Regulations 2017 [827001]
          for the provision of Account Information and Payment Initiation
          services.
        title: FCA Authorisation
      secure_connection:
        message: >-
          Data is securely retrieved in read-only format and only for the
          duration agreed with you.You have the right to withdraw your consent
          at any time.
        title: Secure Connection
      title: About the access
    btn: Proceed
    btn_tip: >-
      You will be securely transferred to your bank for authentication and to
      process the payment. Yapily Connect will share these details with your
      bank, where you will then be asked to confirm the payment setup.
    go_pay:
      message: We are securely transferring you to {bankName} for authentication
      title: |
        Payment details
        {price} to {name}
    need_permission_tip: >-
      We need your permission to securely initiate payments from your bank
      account, via Yapily Connect, an FCA regulated payment initiation provider
      for Pinvo.
    payee_info:
      sub_title: MERCHANT ASPSP
      title: Payee information
    payer_info:
      bank: Bank
      sub_title: YOUR ASPSP
      title: Payer information
    powered_by: Powered by
    title: Permission to make a payment
  complete:
    btn: Finish
    invoice_no: Invoice No.
    not_register:
      actions:
        - Register Pinvo
      message: >-
        {action0} to manage your vendor payments, track and organize invoices,
        and do much more.
    notice: >-
      Notice{':'} Amendment or cancellation of the payment can only be done at
      your bank app/website.
    payee_info:
      bank_name: Bank Name
    save_tip: Save these payment details for future transactions and refunds?
    tip:
      failed: >-
        Your payment order failed as per bank's feedback. You can try to
        schedule another payment again.
      mark_as_paid:
        - This payment request has been marked as paid on {date}.
        - If you have any questions,please contact Ace Marketing {email}
      normal: >-
        Your payment order has been submitted. It may take up to two hours to
        complete.
      pending: Your payment is being processed. The status will be updated shortly.
      pending_other: >-
        Your payment order has been submitted, pending further authorization,
        and the status will be updated timely.
      schedule: >-
        Your payment order has been submitted and is scheduled to go through on
        {date}.
    total_paid: Total Paid
    transaction_id: Transaction ID
  consent:
    account_name: Account Name
    account_number: Account Number
    btn: Proceed
    pay_by_bank:
      bank:
        error: Bank
        placeholder: Search Bank
        title:
          - Select your Bank
          - from other banks
      payment_date:
        error: Payment Date
        placeholder: Select Payment Date
        title: Select Payment Date
      payment_time:
        not_selected: 'Notes: There is no bank selected'
        not_support: 'Notes: This bank does not support future payment.'
        radio0: Pay now
        radio1: Select future date
        title: Select pay date
      psu_id:
        error: PSU ID
        placeholder: Input PSU ID
        title: PSU ID
      saved_bank:
        error: Bank
        title: from your saved account
      title:
        - Pay by bank
        - Choose how you’d like to pay
    payee_name: Payee Account Name
    payment_reference: Payment Reference
    payment_total: Payment Total
    sort_code: Sort Code
  failure:
    btn: Try again
    help: Need help? We are always available at
    tip: Sorry, it seems like this link is not valid anymore.
  step:
    authenticate: Authenticate
    complete: Complete
    consent: Consent
    failed: Failed
register: Registered office address
resend: Resend payment requests
resend_code_success: >-
  We emailed you a new verification code. If you don't have it, please check if
  it is in spam or retry in a few minutes.
reset-pwd:
  action-btn: Reset your password
  confirm-pwd: Confirm password
  new-pwd: New password
  success: Password is reset successfully. Please login again.
  title: Enter your new password
result_code:
  PINVO_CONTACT_DUPLICATE:
    - message: contact already exists
  PINVO_INVOICE_NO_DUPLICATE:
    - message: invoice number already exists
  PINVO_ACCOUNT_NO_ILLEGAL:
    - message: Account number is illegal
  PINVO_CHANGE_PASSWORD_PARAM_ILLEGAL:
    - message: New password is same as old password
  PINVO_LOGIN_ID_ALREADY_EXIST:
    - actions:
        - continue to login
      message: This email is already registered. If it's yours, please {action0}
  PINVO_LOGIN_ID_NOT_EXIST:
    - actions:
        - registered
      message: email is not {action0}
  PINVO_ORG_LEGAL_NO_IS_BLANK:
    - message: Company legal number is blank
  PINVO_USER_LOGIN_PWD_ERROR:
    - message: Email or password is incorrect. Please try again
  PINVO_USER_LOGIN_PWD_ERROR_TIMES_REACH_UPPER_LIMIT:
    - message: Log in attempts are over limit
  PINVO_USER_LOGIN_STATUS_ILLEGAL:
    - actions:
        - registered
      message: email is not {action0}
  PINVO_USER_NOT_REGISTER_EMAIL_PWD_LOGIN:
    - actions:
        - registered
      message: email is not {action0}
  PINVO_ORG_CREATE_INVOICE_LIMIT_EXCEED:
    - message: Your invoice creation attempts have reached the limit. To request an increase, please contact us at experts{'@'}pinvo.ai via email.
  PINVO_VALIDATE_OTP_FAILED:
    - message: Verification error. Please check your verification code
  otp_too_many:
    - message: >-
        Verification is temporarily disabled due to too many failed attempts.
        Please contact us for assistance.
route:
  title:
    get_paid: Get paid
    pay: Pay
    support: Support
send: Send payment requests
setting: Settings
settings:
  account-name: Account Name
  account-number: Account Number
  accounting_async:
    connect_btn: Connect
    content:
      desc:
        disconnected: >
          You’ve disconnected {company} Pinvo account from the {name} account
          {accounting_company}.

          Reconnected it below to resume its syncing.
        init: >-
          Connect your account to sync bills, invoices, contacts, and payments
          between Pinvo and {name}.
        success: >-
          Your Pinvo account automatically syncs with your {account} {name}
          account.
        wfs: Here {be_word} {num} payment methods need to match to {name} accounts.
      need_match: Payment accounts need to match
      title:
        default: '{name}'
        success: '{name} is connected'
        wfs: '{name} syncing is to be done'
    continue_btn: Continue
    disconnect_btn: Disconnect
    reconnect_btn: Reconnect
    subtitle: >-
      Connect your accounting software to sync your bills, invoices, contacts,
      and payments with Pinvo.
    sync_btn:
      - Sync now
      - Syncing...
    title: Accounting software sync
    tool_tip:
      sync: Sync now to {name}
      time: 'Last sync: {time}'
  cancel_btn: Cancel
  card:
    company: Company
    payments: Payments
    personal: User
  company:
    address: Company address
    desc: Edit the company name, address and industry
    group_name:
      - Company
      - User
      - Payments
    industry: Industry
    vat_no: Vat No.
    name: Company name
    tip: This is your trading name. If this is different to your registered name, be sure to update your registered name in the ‘Legal info’ section.
    title: Company basics
    upload: Upload the company logo
  edit_btn: Edit
  new_btn: New
  legal:
    country: Country of incorporation
    date: Date of incorporation
    desc: Update the company's legal information
    name: Business legal name
    nature: Nature of business
    number: Company number
    register: Registered office address
    tip: The official registration name for legal and tax purpose.
    title: Legal info
    type: Business type
  match:
    action: Match account
    no-category:
      - Need to match the payment account in Xero.
      - Match account.
    no-connect:
      - >-
        The linked payment account in Xero has been archived, payement through
        this card won't be synced to Xero.
      - Match a new account in Xero.
    success: Connected to account {category} in Xero.
  no: No
  payments:
    desc: Accounts you used to pay are saved here
    empty-tip:
      action: Pay your first bill now
      message: No payment information on file yet. {action} on Pinvo.
    subtitle:
      - Accounts you used to pay are saved here
      - The accounts you have used to make payment will be stored here.
    title: Pay accounts
  pending: PENDING MATCH
  profile:
    desc: Edit user name or profile picture
    email: Email
    first_name: First name
    last_name: Last name
    title: User profile
    upload: Upload a profile picture
  pwd:
    confirm: Confirm password
    current: Current password
    desc: Set a new password
    inconsistent_warning: Inconsistent inputs twice
    length_warning: Minimum 8 characters
    new: New password
    title: Change password
  receiving:
    desc: Edit account info for getting paid
    title: Get paid accounts
  save_btn: Save
  sort-code: Sort Code
  title: Settings
sorry: Sorry
success:
  create_contact: A new contact is created.
ai:
  title: Pinvo AI
  prefab: |
    Hi, how can I help you today? To get started, and make the most out of your chat with me, try posing questions or utilising prompts related to finance. Some examples are:
    "What is compound interest?"
    "How do I create a budget?"
    "Explain the fundamentals of investing in shares."
  content: |
    Meet Pinvo Al, a super handy chatbot that's here to lend a hand and help you with all your finance-related questions.
    While it strives to be helpful, there might be some cases where it can't handle more specific or complex financial scenarios.
    So, we recommend using Pinvo Al as your trusty sidekick, rather than relying on it solely when it comes to making major financial decisions.
support:
  contact:
    title: Contact us
    email: >-
      Got questions or feedback? Contact us at {email}. Our product manager will
      respond within 24 hours.
  qa:
    title: How can we help?
    content:
      - Hi there!
      - You've reached our FAQ page. We're currently working on creating helpful content to address any questions you may have.
      - Please bear with us as we finalise the details.
      - Thanks for your patience, we'll have this ready for you soon! 🙏

table:
  get_paid:
    action:
      cancel: Cancel
      delete: Delete
      edit: Edit
      mark: Mark as paid
      resend: Resend
      un_mark: Change to unpaid
    column:
      amount: Amount
      customer: Customer
      due_date: Due date
      issue_date: Issue date
      invoice_no: Invoice no.
      source: Source
      status: Status
    status:
      confirm: To confirm
      mark: Marked as paid
      paid: Paid
      reminder: Reminder
      scheduled: Scheduled
      sent: Sent
  pay:
    action:
      cancel: Cancel
      delete: Delete
      edit: Edit
      mark: Mark as paid
      review: Review
      schedule: Pay
      un_mark: Change to unpaid
    column:
      amount: Amount
      deduct_date: Scheduled pay date
      due_date: Due date
      initiated: Source
      invoice_no: Invoice no.
      paid_date: Paid date
      source: Source
      status: Status
      vendor: Vendor
    copy-email-success: Copy the email succuss
    email-card:
      desc: |
        Forward all your invoices to your Pinvo email address, and Pinvo will instantly upload them to your Pay Inbox so you don’t have to.
        Copy your email address below, and get sending.

      title: Skip uploading invoices and send them straight to your inbox
    email-tip: |
      Do you know there is a way to skip uploading and have your invoices in the Inbox?
      Forward those invoices you receive through email to the address we reserve for you. Pinvo will scan them in the background so you don’t wait at all.

      {email}
    filter-tip: Filter bills
    initiated:
      email: Captured from emails
      manual: Manual entry
      orc: Upload
      vendor: Request from vendor
    status:
      mark: Marked as paid
      paid: Paid
      supposed: Supposed to be paid
thank_you: Thank you
toast:
  invoice_not_send: The payment link will only be generated after you click 'send'
  need_select_bank: You need to select a bank
  payment_send_success: Payment request sent
  send_dialog_confirm: OK
  send_dialog_content: |
    What's next?
    You’ll be notified when your customer:
    (1)views your payment request
    (2) makes or schedules a payment through the link embedded in the email.
  send_dialog_title: Payment request is sent.
unit:
  currency: £
components:
  address:
    post_code:
      title: Postcode
      error: You need a regular expression that matches a U.K. postcode.
    address_line1: Address line 1
    address_line2: Address line 2
    city: City/Town
    state: Country
  industry:
    title: Industry
    placeholder: Which industry does your company operate in?
