import { ApiEnum } from './swagger/apiEnum'
import type { ManageReceivingBankAccountRequest, OrgCreateRequest, OrgInfo, OrgModifyRequest, RemoveReceivingBankAccountRequest, UkCompanyInfo, UkCompanyQueryRequest, UkOrgVATNoCheckRequest } from './swagger/apiModel'

export const orgCreateApi = async (data: OrgCreateRequest) => {
  return await http.post<OrgInfo>(ApiEnum.OrgCreate, {
    data,
  })
}
export const orgQueryOwnedOrgApi = async () => {
  return await http.post<OrgInfo>(ApiEnum.OrgQueryOwnedOrg, {
  })
}

export const orgModifyApi = async (data: DeepPartial<OrgModifyRequest>) => {
  return await http.post<OrgInfo>(ApiEnum.OrgModify, {
    data,
  })
}

export const orgManageBankAccountApi = async (data: ManageReceivingBankAccountRequest) => {
  return await http.post<OrgInfo>(ApiEnum.BankAccountManageReceivingBankAccount, {
    data,
  })
}

export const bankAccountRemoveReceivingBankAccountApi = async (data: RemoveReceivingBankAccountRequest) => {
  return await http.post(ApiEnum.BankAccountRemoveReceivingBankAccount, {
    data,
  })
}

export const ukCompanySearchByNameApi = async (data: UkCompanyQueryRequest) => {
  const res = await http.post<UkCompanyInfo[]>(ApiEnum.UkCompanySearchByName, {
    data,
  })
  return res || []
}

export const ukCompanyQueryByNoApi = async (data: UkCompanyQueryRequest) => {
  const res = await http.post<UkCompanyInfo>(ApiEnum.UkCompanyQueryByNo, {
    data,
  })
  return res || []
}

export const ukOrgCheckVATNoApi = async (data: UkOrgVATNoCheckRequest) => {
  return await http.post<boolean>(ApiEnum.UkOrgCheckVATNo, {
    data,
  })
}
