import { setupLayouts } from 'virtual:generated-layouts'
import type { UnwrapRef } from 'vue'
import generatedRoutes from '~pages'

const routes = setupLayouts(generatedRoutes)

export const routerOptions = ref(routes)

consola.info(routes)

export const useAdaptationRouteQueryWithMobile = <T extends null | undefined | string | string[] = null | string | string[]>(key: string, defaultValue: T, options: { pcReactive?: boolean; pcOption?: any } = {}): [Ref<T> | Ref<UnwrapRef<T>>, Fn] => {
  const { back } = useGo()
  const { pcReactive, pcOption } = options
  const reactiveOption = smallerMD.value ? {} : pcOption
  const value = (smallerMD.value || pcReactive) ? useRouteQuery<T>(key, defaultValue, { mode: 'push', ...reactiveOption }) : ref<T>(defaultValue)

  const reset = (v: T) => {
    if (smallerMD.value)
      back()
    else
      value.value = v
  }

  return [value, reset]
}
