const breakpoints = useBreakpoints({
  sm: 375,
  md: 768,
  xl: 1200,
})

export const isSM = breakpoints.between('sm', 'md')
export const isMD = breakpoints.between('md', 'xl')
export const isXL = breakpoints.greaterOrEqual('xl')
export const greaterOrEqualMD = breakpoints.greaterOrEqual('md')
export const greaterMD = breakpoints.greater('md')
export const smallerOrEqualMD = breakpoints.smallerOrEqual('md')
export const smallerMD = breakpoints.smaller('md')

export const isEmbed = window.location.search.includes('embed=true')
