<script setup lang="ts">
// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,

import type { GlobalThemeOverrides, MessageProviderProps } from 'django-naive-ui'
import { darkTheme, dateEnGB, enGB } from 'django-naive-ui'
// import { useRegisterSW } from 'virtual:pwa-register/vue'

// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'Pinvo - Payment Automation Platform',
  meta: [
    { name: 'description', content: 'Make Ease Payment' },
    {
      name: 'theme-color',
      content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
    },
  ],
})

const { locale, availableLocales } = useI18n()
// const { createConfirm } = useNwMessage()

// const { needRefresh } = useRegisterSW({
//   onNeedRefresh: () => {
//     consola.info('need refresh')
//     createConfirm({
//       title: '有新版本请更新',
//     })
//   },
// })

watch(currentLocal, (v) => {
  consola.info('修改语言：', v, availableLocales)
  locale.value = v
  window.location.reload()
})

onMounted(() => {
  // const ele = document.getElementById('full-loading')
  // ele?.remove()
})

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: '#18978f',
    inputColorDisabled: 'rgba(0,0,0,0)',
    fontFamily: 'Poppins, v-sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  Skeleton: {
    borderRadius: '0.8rem',
    color: '#F4F4F4',
  },
  Message: {
    color: 'black',
    colorSuccess: 'black',
    colorError: 'black',
    iconColorSuccess: 'white',
    textColorSuccess: 'white',
    iconColorError: 'white',
    textColorError: 'white',
    borderRadius: '0.8rem',
  },
  InternalSelectMenu: {
    optionPaddingMedium: '0 16px',
    optionHeightMedium: '44px',
  },
  Form: {
    labelHeightLarge: '0',
    labelHeightMedium: '1.6rem',
    feedbackHeightLarge: '0',
    feedbackHeightMedium: '0',
    feedbackTextColor: '#8E8D8C',
    feedbackFontSizeMedium: '1.4rem',
    blankHeightMedium: '0',
    labelFontSizeLeftMedium: '1.2rem',
    labelFontSizeTopMedium: '1.2rem',
    labelTextColor: '#8E8D8C',
    labelPaddingVertical: '0',
    feedbackTextColorWarning: '#8E8D8C',
    lineHeight: '1',

  },
  DatePicker: {
    itemSize: '3.2rem',
    itemBorderRadius: '0.6rem',
    itemCellWidth: '4rem',
    itemCellHeight: '4rem',
    calendarTitleHeight: '3.8rem',
  },
  DataTable: {
    tdPaddingLarge: '2rem 2.4rem',
    thPaddingLarge: '2.4rem 2.4rem',
    borderRadius: '0.8rem',
  },
  Pagination: {
    itemTextColorHover: 'var(--color-primary)',
  },
  Space: {
    gapLarge: '16px 16px',
  },
  Dropdown: {
    borderRadius: '1.2rem',
    optionHeightMedium: '4.4rem',
  },
  Checkbox: {
    sizeLarge: '2.4rem',
    borderRadius: '0.4rem',
  },
  Button: {
    fontWeight: '600',
    heightLarge: '5.6rem',
    fontSizeLarge: '1.8rem',
    fontSizeMedium: '1.6rem',
    heightMedium: '4.8rem',
    heightSmall: '3.2rem',
    iconSizeTiny: '1.6rem',
    paddingLarge: '1.6rem 2.4rem',
    paddingTiny: '0.5rem 0.5rem',
    paddingMedium: '1.2rem 2rem',
    paddingSmall: '0.8rem 1.6rem',
    colorPrimary: 'var(--color-primary)',
    border: '2px solid var(--color-primary)',
    textColor: 'var(--color-primary)',
    colorHover: 'var(--color-primary-25)',
    textColorHover: 'var(--color-primary-500)',
    borderHover: '2px solid var(--color-primary)',
    colorPressed: 'var(--color-primary-25)',
    colorFocus: 'var(--color-primary-25)',
    textColorFocus: 'var(--color-primary-500)',
    borderFocus: '1px solid var(--color-primary-500)',
    borderPressed: '1px solid var(--color-primary-25)',
    textColorPressed: 'var(--color-primary-600)',
    colorHoverPrimary: 'var(--color-primary-500)',
    borderHoverPrimary: 'var(--color-primary-500)',
    colorPressedPrimary: smallerMD.value ? 'var(--color-primary)' : 'var(--color-primary-600)',
    borderPressedPrimary: smallerMD.value ? 'var(--color-primary)' : 'var(--color-primary-600)',
    colorFocusPrimary: 'var(--color-primary-600)',
    borderFocusPrimary: 'var(--color-primary-600)',
  },
  InternalSelection: {
    border: '',
    color: 'rgba(0,0,0,0)',
    fontSizeMedium: '1.6rem',
    paddingSingle: '0',
    paddingMultiple: '0',
    heightMedium: '2.4rem',
    arrowSize: '2.4rem',
    // colorActive: 'rgba(0,0,0,0)',
    // placeholderColor: 'rgba(0,0,0,0)',
    borderHover: '0px solid var(--color-primary)',
    borderFocus: '0px solid var(--color-primary)',
    borderError: 'unset',
    borderHoverError: '0px solid var(--color-primary)',
    borderFocusError: '0px solid var(--color-primary)',
    boxShadowFocusError: '',
    boxShadowActive: '',
    boxShadowFocus: '',
    colorActive: '',
    borderActive: '0',
  },
  Input: {
    heightMedium: '2.4rem',
    borderHover: '0px solid var(--color-primary)',
    borderFocus: '0px solid var(--color-primary)',
    colorDisabled: 'rgba(0,0,0,0)',
    borderDisabled: '0px solid var(--color-primary)',
    textColorDisabled: '#454443',
    borderError: 'unset',
    borderHoverError: 'unset',
    borderFocusError: 'unset',
    boxShadowFocusError: 'unset',
    color: 'rgba(0,0,0,0)',
    colorFocus: 'rgba(0,0,0,0)',
    colorFocusError: 'rgba(0,0,0,0)',
    boxShadowFocus: 'unset',
    border: '0px solid var(--color-primary-100)',
    borderRadius: '0.8rem',
    paddingMedium: '0',
    paddingSmall: '0',
    fontSizeMedium: '1.6rem',
    heightSmall: '1.2rem',
    fontSizeSmall: '1.2rem',
    lineHeight: '1',
    iconSize: '2rem',
  },
  Spin: {
    color: 'var(--color-primary)',
    opacitySpinning: '0.4',
  },
}

const placementRef = ref<MessageProviderProps['placement']>('top')
const changePlacement = (val: MessageProviderProps['placement']) => {
  placementRef.value = val
}
</script>

<template>
  <NConfigProvider :theme="isDark ? darkTheme : null" :date-locale="dateEnGB" :locale="enGB" :theme-overrides="themeOverrides" class="h-full">
    <NDialogProvider>
      <RouterView />
    </NDialogProvider>
    <NMessageProvider :placement="placementRef">
      <NwMessageProvider @placement="changePlacement" />
    </NMessageProvider>
    <NwConfirmDialogProvider class="z-50" />
    <NGlobalStyle />
  </NConfigProvider>
</template>

<style>
#app {
  @apply h-full;
}
</style>
