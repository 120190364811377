import type { Deferred } from '~/utils/deferred'

let embedPromise: Deferred<any>

export function getEmbedPromise() {
  return embedPromise
}

export function setEmbedPromise(promise: Deferred<any>) {
  embedPromise = promise
}
