import { ApiEnum } from './swagger/apiEnum'
import type { ApiDataEncryptConfigRecordInfo } from './swagger/apiModel'

/**
 * 获取aeskey
 * @param clientRsaPublicKey
 * @param serverRsaKeyId
 * @returns
 */
export const fetchServerAesKeyApi = async (clientRsaPublicKey: string, serverRsaKeyId: string) => {
  return await http.post<ApiDataEncryptConfigRecordInfo>(ApiEnum.ApiDataEncryptFetchServerAesKey, {
    data: { clientRsaPublicKey, serverRsaKeyId },
    meta: { aes: false },
  })
}

/**
 * 获取服务端rsa_publish_key
 * @returns
 */
export const fetchServerRsaPublicKeyApi = async () => {
  return await http.post<ApiDataEncryptConfigRecordInfo>(ApiEnum.ApiDataEncryptFetchServerRsaPublicKey, {
    data: {},
    meta: { aes: false },
  })
}

/**
 * 根据aes_key_id获取aes_key
 * @param aesKeyId
 * @param clientRsaPublicKey
 * @returns
 */
export const queryServerAesKeyByAesKeyIdApi = async (aesKeyId: string, clientRsaPublicKey: string) => {
  return await http.post<ApiDataEncryptConfigRecordInfo>(ApiEnum.ApiDataEncryptQueryServerAesKeyByAesKeyId, {
    data: { aesKeyId, clientRsaPublicKey },
    meta: {
      aes: true,
    },
  })
}
