import BigNumber from 'bignumber.js'

export function bignumber(v?: number | string | null | BigNumber) {
  return BigNumber(v || 0)
}

export const plus = (a: number | string | BigNumber, b: number | string | BigNumber) => {
  const _a = new BigNumber(a || 0)
  const _b = new BigNumber(b || 0)
  return _a.plus(_b)
}

export const minus = (a: number | string, b: number | string) => {
  const _a = new BigNumber(a)
  const _b = new BigNumber(b)
  return _a.minus(_b)
}

export const division = (a: number | string, b: number | string) => {
  const _a = new BigNumber(a)
  const _b = new BigNumber(b)
  return _a.div(_b)
}

export const multiplied = (a?: number | string, b?: number | string) => {
  const _a = new BigNumber(a || 0)
  const _b = new BigNumber(b || 0)
  return _a.multipliedBy(_b)
}

export const fixed = (a: number | string, decimalPlaces = 0) => {
  const _a = new BigNumber(a || 0)
  _a.toFixed(decimalPlaces)
}
