<script lang="ts" setup>
import { createNamespace } from '~/utils/bem'

defineEmits(['placement'])
const [name] = createNamespace('message-provider')
const messageRef = ref()
onMounted(() => {
  setupNwMessageIns(messageRef.value)
})
</script>

<template>
  <div :class="[name]">
    <NwMessage ref="messageRef" @placement="$emit('placement', $event)" />
  </div>
</template>

<style lang="less" scoped>
    .pinvo-message-provider{

    }
</style>
