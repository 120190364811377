import type { AjaxPlugin } from 'nice-axios'
import { CacheKey } from '~/enums/cache'

export const tokenPlugin: AjaxPlugin = async (next, config) => {
  const token = getLocal(CacheKey.TOKEN, '')
  // jwt token
  config.headers!['PINVO-USER-LOGIN-TOKEN'] = token.value

  return next()
}
