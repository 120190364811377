export enum RoutePath {
  Root = '/',
  Login = '/login',
  Dashboard = '/dashboard',
  Base = '/invoice',
  Demo = '/demo',
  Validate = '/validate',
  OrgCreate = '/org/create',
  Setting = '/setting',
  GetPaid = '/invoice',
  Pay = '/bill',
  PaymentResult = '/payment/result',
  PaymentFailed = '/payment/failed',
  PaymentProcess = '/payment/process',
  InvoiceCreate = '/invoice/create',
  PreviewInvoice = '/preview/invoice',
  BillCreate = '/bill/create',
  BillOcr = '/bill/ocr',
  BillReview = '/bill/review',
  Redirect = '/redirect',
  Kyb = '/kyb',
  BatchRequest = '/batch_request',
  AccountingSync = '/accounting/sync',
  AccountingCallback = '/accounting/callback/:platform',
  ContactCreate = '/contact/create',
  Contact = '/contact',
}
