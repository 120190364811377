import type { I18n } from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import { dateTimeFormats as datetimeFormats } from '../../locales/formats/dateTimeFormats'
import { numberFormats } from '../../locales/formats/numberFormats'
import { type UserModule } from '~/types'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
export const localMessages = Object.fromEntries(
  Object.entries(
    import.meta.glob<{ default: any }>('../../locales/*.y(a)?ml', { eager: true }))
    .map(([key, value]) => {
      const yaml = key.endsWith('.yaml')
      return [key.slice(14, yaml ? -5 : -4), value.default]
    }),
)

let i18n: I18n

export const install: UserModule = ({ app }) => {
  i18n = createI18n({
    legacy: false,
    locale: currentLocal.value,
    fallbackLocale: currentLocal.value,
    messages: localMessages,
    datetimeFormats,
    numberFormats,

  })
  app.use(i18n)
}

export const getCurrentLocal = () => {

}

export const getI18nIns = () => {
  return i18n
}
