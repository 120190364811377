import type { Router } from 'vue-router'
import { parseQuery } from 'vue-router'
import { RoutePath } from '~/enums/path'
import { getI18nIns } from '~/modules/i18n'
import { setDocumentTitle } from '~/utils/dom'

const whitePathList = [RoutePath.OrgCreate, RoutePath.Validate, RoutePath.Login] as string[]

export const setupRedirectGuard = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    const { _redirect_path } = from.query
    if (_redirect_path && to.path !== RoutePath.Redirect && from.path !== RoutePath.Redirect && _redirect_path !== to.path && !whitePathList.includes(to.path)) {
      const redirectPath = decodeURIComponent(_redirect_path as string)
      const [path, q] = redirectPath.split('?')
      const query = parseQuery(q || '')
      next({
        path: RoutePath.Redirect,
        query: { ...query, _redirect_path: path },
        replace: true,
      })
      return
    }
    next()
  })

  router.afterEach((to) => {
    const i18n = getI18nIns()
    if (to.meta.title)
      // @ts-expect-error:忽略类型报错
      setDocumentTitle(`${globSetting.title} | ${i18n.global.t(to.meta.title as string || '')}`)
    else
      setDocumentTitle(`${globSetting.title}`)
  })
}
