<script lang="ts" setup>
import type { MaybeComputedRef } from '@vueuse/shared'
import anime from 'animejs'
import { computed, nextTick, ref, watch } from 'vue'

interface IProps {
  show: boolean
  maskable?: boolean
  zIndex?: number
  lockScroll?: boolean
  className?: string
  showContentAnimation?: boolean
  contentClassName?: string
}

const props = withDefaults(defineProps<IProps>(), {
  show: false,
  zIndex: 2900,
  maskable: true,
  lockScroll: true,
  showContentAnimation: false,
})

const emits = defineEmits(['update:show', 'opened', 'closed'])

// ref 声明
const _show = ref(false)
const maskRef = ref()
const maskContainer = ref()
const scrollParent = useScrollParent(maskContainer)

// computed

const animationTargets = computed(() => {
  if (props.showContentAnimation)
    return maskContainer.value

  return maskRef.value
})

// hook

const isLocked = useScrollLock(scrollParent as MaybeComputedRef<Window>)

watch([() => props.show, () => props.lockScroll], () => {
  isLocked.value = props.show && props.lockScroll
})

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      _show.value = true
      nextTick(() => {
        if (maskRef.value) {
          anime({
            targets: animationTargets.value,
            duration: 600,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            complete: () => {
              emits('opened')
            },
          })
        }
      })
    }
    else {
      if (maskRef.value) {
        nextTick(() => {
          anime({
            targets: animationTargets.value,
            duration: 600,
            opacity: [1, 0],
            easing: 'easeInOutQuad',
            complete: () => {
              _show.value = false
              emits('closed')
            },
          })
        })
      }
    }
  },
  { immediate: true },
)

// 避免组件消失后出现无法滑动问题
// onDeactivated(() => {
//   document.body.style.overflow = '';
// });

function close() {
  emits('update:show', false)
}
</script>

<template>
  <div
    v-show="_show"
    ref="maskContainer"
    class="xcc-overlay fixed w-full h-full top-0 left-0 bottom-0 right-0 safe-area m-auto overflow-hidden"
    :class="className"
    :style="{ zIndex }"
  >
    <div ref="maskRef" class="xcc-mask absolute top-0 w-full h-full" @click="maskable && close()" @touchmove.prevent="" />
    <!-- <div ref="contentRef" class="xcc-mask-content relative w-full h-full pointer-events-auto" :class="contentClassName">
        </div> -->
    <slot />

    <slot name="outside" />
  </div>
</template>

  <style lang="less" scoped>
    .xcc-overlay {
      .xcc-mask {
        background-color: rgba(0, 0, 0, 0.6);
        &-content {
          // height: inherit;
        }
      }
    }
  </style>
