export enum CacheKey {
  COLOR_SCHEME = '_COLOR_SCHEME_',
  KEEP_ALIVE_PAGES = '_KEEP_ALIVE_PAGES_',
  TOKEN = '_TOKEN_',
  USER_INFO = '_USER_INFO_',
  ORG_INFO = '_ORG_INFO_',
  SIGN_IN_INFO = '_SIGN_IN_INFO_',
  LOCAL_LANGUAGE = '_LOCAL_LANGUAGE',
  CHECK_INVOICES = '_CHECK_INVOICES_',
  CHECK_BILLS = 'CHECK_BILLS',
  SELECTED_INVOICE = 'SELECTED_INVOICE',
  SELECTED_BILL = 'SELECTED_BILL',
  SHOWED_BILLS_EMAIL_TIP = 'SHOWED_BILLS_EMAIL_TIP',
  SENDED_INVOICE_REQUEST = 'SENDED_INVOICE_REQUEST',
}
