export const useMessageActions = () => {
  const { t } = useI18n()

  const getMessageActions = (path: string, actions?: string[]) => {
    const actionTexts = {} as Record<string, any>
    if (actions && actions.length) {
      actions.forEach((_: any, i: number) => {
        actionTexts[`action${i}`] = t(`${path}.actions[${i}]`)
      })
    }
    return actionTexts
  }

  return { getMessageActions }
}

export const useLocalMessage = () => {
  const { t, getLocaleMessage, locale } = useI18n()
  const { getMessageActions } = useMessageActions()
  const getResultMessage = (code?: string, fillText: Record<string, any> = {}, index = 0) => {
    const localMessage = getLocaleMessage(locale.value) as any
    if (!code || !localMessage.result_code[code])
      return { fullText: code || '', actionTexts: undefined }

    const actionTexts = getMessageActions(`result_code.${code}[${index}]`, localMessage.result_code[code][index]?.actions)

    const fullText = t(`result_code.${code}[${index}].message`, { ...fillText, ...actionTexts })
    // 返回全填充好的数据 以及action个数
    return { fullText, actionTexts }
  }

  return { getResultMessage, t, locale }
}
