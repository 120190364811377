import CryptoJS from 'crypto-js'
const KEY = 'six--django'
const encryptAlgorithm = {
  name: 'RSA-OAEP',
  modulusLength: 2048,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  extractable: false,
  hash: {
    name: 'SHA-256',
  },
}

const arrayBufferToBase64String = (arrayBuffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(arrayBuffer)
  let byteString = ''
  for (let i = 0; i < byteArray.byteLength; i++)
    byteString += String.fromCharCode(byteArray[i])

  return window.btoa(byteString)
}

const base64StringToArrayBuffer = (b64str: string) => {
  const byteStr = window.atob(b64str)
  const bytes = new Uint8Array(byteStr.length)
  for (let i = 0; i < byteStr.length; i++)
    bytes[i] = byteStr.charCodeAt(i)

  return bytes.buffer
}

const arrayBufferToText = (arrayBuffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(arrayBuffer)
  let str = ''
  for (let i = 0; i < byteArray.byteLength; i++)
    str += String.fromCharCode(byteArray[i])

  return str
}

const textToArrayBuffer = (str: string) => {
  const buf = window.unescape(encodeURIComponent(str)) // 2 bytes for each char
  const bufView = new Uint8Array(buf.length)
  for (let i = 0; i < buf.length; i++)
    bufView[i] = buf.charCodeAt(i)

  return bufView
}

export const encryptByDES = (message: string, key: string = KEY) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}

export const decryptDES = (message: string, key: string = KEY) => {
  if (!message)
    return ''
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const plaintext = CryptoJS.DES.decrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return plaintext.toString(CryptoJS.enc.Utf8)
}

// 获取密钥对
export const getRsaKeys = async () => {
  if (!window.crypto.subtle)
    return
  const key = await window.crypto.subtle.generateKey(
    encryptAlgorithm,
    true, // whether the key is extractable (i.e. can be used in exportKey)
    ['encrypt', 'decrypt'], // must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
  )
  const keydata1 = await window.crypto.subtle.exportKey('pkcs8', key.privateKey)
  const keydata2 = await window.crypto.subtle.exportKey('spki', key.publicKey)
  const privateKey = arrayBufferToBase64String(keydata1)
  const publicKey = arrayBufferToBase64String(keydata2)
  const code = {
    privateKeyStr: privateKey,
    publicKeyStr: publicKey,
    ...key,
  }
  return code
}

/**
 * @description aes加密
 * @param {string} word 需要加密的字符串
 */

export const aesEncrypt = (word: string, aesKey: string, aesIV?: string) => {
  if (!word || !aesKey)
    return ''

  const keyHex = CryptoJS.enc.Hex.parse(aesKey)
  const messageHex = CryptoJS.enc.Utf8.parse(word)
  let iv
  if (aesIV)
    iv = CryptoJS.enc.Utf8.parse(aesIV)

  const encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}

/**
 * @description aes解密
 * @param {string} word 需要解密的字符串
 */
export const aesDecrypt = (word: string, aesKey: string, aesIV?: string) => {
  if (!word || !aesKey)
    return ''
  const key = CryptoJS.enc.Hex.parse(aesKey)
  let iv
  if (aesIV)
    iv = CryptoJS.enc.Utf8.parse(aesIV)

  const decrypt = CryptoJS.AES.decrypt(word, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return decrypt.toString(CryptoJS.enc.Utf8).toString()
}

/**
 * rsa 解密
 * @param privateKey
 * @param dataString
 * @returns
 */
export const rsaDecode = async (privateKey: CryptoKey, dataString: string | ArrayBuffer) => {
  if (!window.crypto.subtle)
    return
  let dataBuffer: ArrayBuffer
  if (typeof dataString === 'string')
    dataBuffer = base64StringToArrayBuffer(dataString)

  else
    dataBuffer = dataString

  const resBuffer = await crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    privateKey,
    dataBuffer,
  )
  return arrayBufferToText(resBuffer)
}

/**
 * rsa 加密
 * @param publishKey
 * @param dataString
 * @returns
 */
export const rsaEncode = async (publishKey: CryptoKey, dataString: string) => {
  if (!window.crypto.subtle)
    return
  const dataBuffer = textToArrayBuffer(dataString)
  const res = await crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    publishKey,
    dataBuffer,
  )
  return arrayBufferToBase64String(res)
}

const convertToPem = (binaryData: ArrayBuffer | string | any, label: string) => {
  let base64Cert = binaryData
  if (typeof binaryData !== 'string')
    base64Cert = arrayBufferToBase64String(binaryData)

  let pemCert = `-----BEGIN ${label}-----\r\n`
  let nextIndex = 0
  while (nextIndex < base64Cert.length) {
    if (nextIndex + 64 <= base64Cert.length)
      pemCert += `${base64Cert.substr(nextIndex, 64)}\r\n`

    else
      pemCert += `${base64Cert.substr(nextIndex)}\r\n`

    nextIndex += 64
  }
  pemCert += `-----END ${label}-----\r\n`
  return pemCert
}

const convertPemToBinary = (pem: string) => {
  const lines = pem.split('\n')
  let encoded = ''
  for (let i = 0; i < lines.length; i++) {
    if (
      lines[i].trim().length > 0
      && !lines[i].includes('-BEGIN RSA PRIVATE KEY-')
      && !lines[i].includes('-BEGIN RSA PUBLIC KEY-')
      && !lines[i].includes('-END RSA PRIVATE KEY-')
      && !lines[i].includes('-END RSA PUBLIC KEY-')
    )
      encoded += lines[i].trim()
  }
  return base64StringToArrayBuffer(encoded)
}

export const importPublicKey = async (key: string) => {
  const publicKey = convertToPem(key, 'RSA PUBLIC KEY')
  return await window.crypto.subtle.importKey('spki', convertPemToBinary(publicKey), encryptAlgorithm, true, ['encrypt'])
}

export const importPrivateKey = async (key: string) => {
  return await window.crypto.subtle.importKey('pkcs8', convertPemToBinary(convertToPem(key, 'RSA PRIVATE KEY')), encryptAlgorithm, true, ['decrypt'])
}
