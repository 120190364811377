import { assign, isString } from 'lodash-es'
import type { RouteLocationRaw, Router } from 'vue-router'
import { RoutePath } from '~/enums/path'
import { QueryKeyEnum } from '~/enums/query'
import { stringifyParams } from '~/utils/dom'

const packRedoRouteInfo = (routeInfo: any) => {
  const { name, path } = routeInfo
  if (path === RoutePath.Redirect)
    return

  if (!routeInfo.query)
    routeInfo.query = {}

  if (name) {
    routeInfo.query._redirect_type = 'name'
    routeInfo.query._redirect_path = String(name)
  }
  else {
    routeInfo.query._redirect_type = 'path'
    routeInfo.query._redirect_path = routeInfo.fullPath || routeInfo.path
  }
  routeInfo.path = RoutePath.Redirect
  consola.info('routeInfo:', routeInfo)
}

export const useGo = () => {
  const router = useRouter()
  const { encrypt, securityStore } = useAesEncryptHook()
  const go = (
    routeInfo: RouteLocationRaw | RoutePath | string,
    options?: {
      isReplace?: boolean
      redirect?: boolean
      blank?: boolean
      encryptQuery?: Record<string, any>
    }) => {
    const _routerInfo: RouteLocationRaw & { path?: string } = {}
    if (isString(routeInfo))
      _routerInfo.path = routeInfo
    else
      assign(_routerInfo, routeInfo)

    if (options?.isReplace)
      _routerInfo.replace = true

    if (options?.encryptQuery) {
      if (!_routerInfo.query)
        _routerInfo.query = {}
      Object.keys(options.encryptQuery).forEach((k) => {
        _routerInfo.query![k] = encrypt(options.encryptQuery![k])
      })

      if (securityStore.serverAesKeyId)
        _routerInfo.query[QueryKeyEnum.NW_K] = securityStore.serverAesKeyId
    }

    if (options?.redirect)
      packRedoRouteInfo(_routerInfo)
    if (!options?.blank) {
      router.push(_routerInfo)
    }
    else {
      const queryStr = stringifyParams(_routerInfo.query)
      window.open(`${_routerInfo.path}?${queryStr}`)
    }
  }
  const back = (routeInfo?: RouteLocationRaw | RoutePath | string) => {
    if (history.state.back)
      router.back()
    else
      router.push(routeInfo || RoutePath.Base)
  }

  return { go, back }
}

export const useRedo = (options: RouteLocationRaw = {}, _router?: Router) => {
  const { push, currentRoute } = _router || useRouter()
  const currRoute = unref(currentRoute.value)
  Object.assign(currRoute, options)
  const { query, params = {}, name, path, fullPath } = currRoute
  function redo(): Promise<boolean> {
    return new Promise((resolve) => {
      if (path === RoutePath.Redirect) {
        resolve(false)
        return
      }
      if (name && Object.keys(params).length > 0) {
        params._redirect_type = 'name'
        params.path = String(name)
      }
      else {
        params._redirect_type = 'path'
        params.path = fullPath
      }
      push({ path: RoutePath.Redirect, params, query }).then(() => resolve(true))
    })
  }
  return redo
}
