/**
  会计软件授权状态展示状态
  */
export enum AccountingAuthRecordShowStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NEED_BIND_ACCOUNT = 'NEED_BIND_ACCOUNT',
  NO_CONNECT = 'NO_CONNECT',
}
/**
  会计软件授权状态枚举
  */
export enum AccountingAuthStatusEnum {
  AUTHED = 'AUTHED',
  CANCELED = 'CANCELED',
  INIT = 'INIT',
}
/**
  会计软件枚举
  */
export enum AccountingSoftwareEnum {
  QB = 'QB',
  SAGE = 'SAGE',
  XERO = 'XERO',
}
/**
  资产状态
  */
export enum AssetStatusEnum {
  NORMAL = 'NORMAL',
  REMOVED = 'REMOVED',
}
/**
  资产关联对象类型
  */
export enum AssetTargetTypeEnum {
  CONTACT_RECEIVING_METHODS = 'CONTACT_RECEIVING_METHODS',
  ORG_PAYMENT_METHODS = 'ORG_PAYMENT_METHODS',
  ORG_RECEIVING_METHODS = 'ORG_RECEIVING_METHODS',
}
/**
  资产类型
  */
export enum AssetTypeEnum {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
}
/**
  账单的操作类型
  */
export enum BillOperationTypeEnum {
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
  MAKE_PINVO_PAYMENT = 'MAKE_PINVO_PAYMENT',
  MARK_AS_PAID_BY_SELF = 'MARK_AS_PAID_BY_SELF',
  MARK_AS_UNPAID_BY_SELF = 'MARK_AS_UNPAID_BY_SELF',
  MODIFY = 'MODIFY',
}
/**
  账单展示类型
  */
export enum BillShowStatusEnum {
  CANCELED = 'CANCELED',
  INIT = 'INIT',
  MARKED_AS_PAID_BY_VENDOR = 'MARKED_AS_PAID_BY_VENDOR',
  PINVO_PAID = 'PINVO_PAID',
  PROCESSING = 'PROCESSING',
  SCHEDULED = 'SCHEDULED',
}
/**
  账单列表分类类型
  */
export enum BillShowTypeEnum {
  EMAIL_OCR_DRAFT = 'EMAIL_OCR_DRAFT',
  INBOX = 'INBOX',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED',
}
/**
  账单来源
  */
export enum BillSourceEnum {
  CREATED_BY_SELF = 'CREATED_BY_SELF',
  RECEIVE_PAYMENT_REQUEST = 'RECEIVE_PAYMENT_REQUEST',
}
/**
  账单状态
  */
export enum BillStatusEnum {
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
  INIT = 'INIT',
  PAID = 'PAID',
}
/**
  业务单据类型
  */
export enum BizOrderTypeEnum {
  BILL_PAYMENT_RECORD = 'BILL_PAYMENT_RECORD',
  PAYMENT_LINK_ORDER = 'PAYMENT_LINK_ORDER',
}
/**
  业务类型
  */
export enum BizTypeEnum {
  BILL = 'BILL',
  PAYMENT_LINK = 'PAYMENT_LINK',
}
/**
  科目状态枚举
  */
export enum CategoryStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
}
/**
  科目类型
  */
export enum CategoryTypeEnum {
  ASSET = 'ASSET',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  LIABILITY = 'LIABILITY',
  REVENUE = 'REVENUE',
  UNKNOWN = 'UNKNOWN',
}
/**
  认证状态
  */
export enum CertStatusEnum {
  CERTIFIED = 'CERTIFIED',
  UN_CERTIFIED = 'UN_CERTIFIED',
}
/**
  联系人类型
  */
export enum ContactTypeEnum {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
}
/**
  邮箱的核验状态
  */
export enum VerificationStatusEnum {
  NO_VERIFICATION = 'NO_VERIFICATION',
  PASS_VERIFICATION = 'PASS_VERIFICATION',
}
/**
  账单录入方式枚举
  */
export enum EntryMethodEnum {
  EMAIL_OCR = 'EMAIL_OCR',
  MANUAL = 'MANUAL',
  OCR = 'OCR',
}
/**
  HTTP内容类型枚举
  */
export enum HttpContentTypeEnum {
  AIFF = 'AIFF',
  ASP = 'ASP',
  AUDIO_BASIC = 'AUDIO_BASIC',
  AUDIO_MPEG = 'AUDIO_MPEG',
  AVI = 'AVI',
  BASIC = 'BASIC',
  BMP = 'BMP',
  CSS = 'CSS',
  FAX = 'FAX',
  GIF = 'GIF',
  HTML = 'HTML',
  IVF = 'IVF',
  JPEG = 'JPEG',
  MID = 'MID',
  MOV = 'MOV',
  MP1 = 'MP1',
  MP2 = 'MP2',
  MP3 = 'MP3',
  MP4 = 'MP4',
  MPEG4 = 'MPEG4',
  MPEGURL = 'MPEGURL',
  MSWORD = 'MSWORD',
  PDF = 'PDF',
  PNETVUE = 'PNETVUE',
  PNG = 'PNG',
  RN_MPEG = 'RN_MPEG',
  SCPLS = 'SCPLS',
  TIFF = 'TIFF',
  TXT = 'TXT',
  VIDEO_MPEG = 'VIDEO_MPEG',
  VND_RN_REALMEDIA_VBR = 'VND_RN_REALMEDIA_VBR',
  WAV = 'WAV',
  WEBP = 'WEBP',
  XML = 'XML',
  X_BMP = 'X_BMP',
  X_ICO = 'X_ICO',
  X_ICON = 'X_ICON',
  X_IMG = 'X_IMG',
  X_JPG = 'X_JPG',
  X_LA_LMS = 'X_LA_LMS',
  X_LIQUID_FILE = 'X_LIQUID_FILE',
  X_LIQUID_SECURE = 'X_LIQUID_SECURE',
  X_M4A = 'X_M4A',
  X_MEI_AAC = 'X_MEI_AAC',
  X_MPEG = 'X_MPEG',
  X_MPG = 'X_MPG',
  X_MS_ASF = 'X_MS_ASF',
  X_MS_BMP = 'X_MS_BMP',
  X_MS_WAX = 'X_MS_WAX',
  X_MS_WM = 'X_MS_WM',
  X_MS_WMA = 'X_MS_WMA',
  X_MS_WMV = 'X_MS_WMV',
  X_MS_WMX = 'X_MS_WMX',
  X_MS_WVX = 'X_MS_WVX',
  X_PIC = 'X_PIC',
  X_PNG = 'X_PNG',
  X_PPT = 'X_PPT',
  X_SGI_MOVIE = 'X_SGI_MOVIE',
  X_XLS = 'X_XLS',
  X_ZIP_COMPRESSED = 'X_ZIP_COMPRESSED',
  ZIP = 'ZIP',
}
/**
  收款单列表展示类型，比如未发送、发送等
  */
export enum InvoiceShowTypeEnum {
  NOT_SENT = 'NOT_SENT',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  SENT = 'SENT',
}
/**
  收款账单状态枚举
  */
export enum InvoiceStatusEnum {
  INIT = 'INIT',
  MARK_AS_PAID = 'MARK_AS_PAID',
  PAID = 'PAID',
  REMOVED = 'REMOVED',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}
/**
  登录方式类型
  */
export enum LoginMethodTypeEnum {
  LOGIN_WITH_PWD = 'LOGIN_WITH_PWD',
  LOGIN_WITH_SSO_GOOGLE = 'LOGIN_WITH_SSO_GOOGLE',
  TRUST_LOGIN = 'TRUST_LOGIN',
}
/**
  登录状态
  */
export enum UserLoginStatusEnum {
  FROZEN = 'FROZEN',
  INACTIVE = 'INACTIVE',
  NORMAL = 'NORMAL',
  REMOVED = 'REMOVED',
}
/**
  媒体类型
  */
export enum MediumTypeEnum {
  AUDIO = 'AUDIO',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
}
/**
  官方分配的邮箱状态
  */
export enum OfficialAssignedEmailStatusEnum {
  FROZEN = 'FROZEN',
  INIT = 'INIT',
  NORMAL = 'NORMAL',
}
/**
  OB支付授权状态
  */
export enum OpenBankingPaymentAuthStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  AWAITING_AUTHORIZATION = 'AWAITING_AUTHORIZATION',
  CONSUMED = 'CONSUMED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
}
/**
  OB支付ISO状态
  */
export enum OpenBankingPaymentISOStatusEnum {
  ACCC = 'ACCC',
  ACCP = 'ACCP',
  ACFC = 'ACFC',
  ACSC = 'ACSC',
  ACSP = 'ACSP',
  ACTC = 'ACTC',
  ACWC = 'ACWC',
  ACWP = 'ACWP',
  CANC = 'CANC',
  PATC = 'PATC',
  PDNG = 'PDNG',
  RCVD = 'RCVD',
  RJCT = 'RJCT',
}
/**
  OB支付状态
  */
export enum OpenBankingPaymentStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}
/**
  操作事件码类型
  */
export enum OperateEventCodeEnum {
  BILL_CANCELED_BY_VENDOR = 'BILL_CANCELED_BY_VENDOR',
  BILL_CREATE = 'BILL_CREATE',
  BILL_DELETED = 'BILL_DELETED',
  BILL_DRAFT_CREATE = 'BILL_DRAFT_CREATE',
  BILL_MARKED_AS_PAID_BY_SELF = 'BILL_MARKED_AS_PAID_BY_SELF',
  BILL_MARKED_AS_PAID_BY_VENDOR = 'BILL_MARKED_AS_PAID_BY_VENDOR',
  BILL_MARKED_AS_UNPAID_BY_SELF = 'BILL_MARKED_AS_UNPAID_BY_SELF',
  BILL_PINVO_PAID = 'BILL_PINVO_PAID',
  BILL_PINVO_SCHEDULED_PAID = 'BILL_PINVO_SCHEDULED_PAID',
  BILL_RECEIVED = 'BILL_RECEIVED',
  BILL_SUPPOSED_TO_BE_PAID = 'BILL_SUPPOSED_TO_BE_PAID',
  BILL_UPDATED_BY_VENDOR = 'BILL_UPDATED_BY_VENDOR',
  CONTACT_CREATE = 'CONTACT_CREATE',
  INVOICE_CANCEL = 'INVOICE_CANCEL',
  INVOICE_CREATE = 'INVOICE_CREATE',
  INVOICE_DELETE = 'INVOICE_DELETE',
  INVOICE_MARK_AS_PAID = 'INVOICE_MARK_AS_PAID',
  INVOICE_MARK_AS_UNPAID = 'INVOICE_MARK_AS_UNPAID',
  INVOICE_PAYMENT_REQUEST_CANCEL = 'INVOICE_PAYMENT_REQUEST_CANCEL',
  INVOICE_PAYMENT_REQUEST_EMAIL_OPEN = 'INVOICE_PAYMENT_REQUEST_EMAIL_OPEN',
  INVOICE_PAYMENT_REQUEST_PAGE_URL_OPEN = 'INVOICE_PAYMENT_REQUEST_PAGE_URL_OPEN',
  INVOICE_PAYMENT_REQUEST_SEND = 'INVOICE_PAYMENT_REQUEST_SEND',
  INVOICE_PAYMENT_REQUEST_UPDATE = 'INVOICE_PAYMENT_REQUEST_UPDATE',
  INVOICE_TO_PAID = 'INVOICE_TO_PAID',
  INVOICE_TO_SCHEDULED = 'INVOICE_TO_SCHEDULED',
  ORG_CREATE = 'ORG_CREATE',
  USER_REGISTER = 'USER_REGISTER',
}
/**
  操作记录扩展信息KEY值枚举
  */
export enum OperateRecordExtInfoKeyEnum {
  NOTE_TO_CUSTOMER = 'NOTE_TO_CUSTOMER',
  REPEAT_PAYMENT_REQUEST_SEND_TIMES = 'REPEAT_PAYMENT_REQUEST_SEND_TIMES',
  SEND_EMAIL = 'SEND_EMAIL',
}
/**
  操作对象类型
  */
export enum OperateTargetTypeEnum {
  BILL = 'BILL',
  CONTACT = 'CONTACT',
  INVOICE = 'INVOICE',
  ORG = 'ORG',
  USER = 'USER',
}
/**
  组织状态
  */
export enum OrgStatusEnum {
  FROZEN = 'FROZEN',
  INACTIVE = 'INACTIVE',
  NORMAL = 'NORMAL',
  REMOVED = 'REMOVED',
}
/**
  组织类型枚举
  */
export enum OrgTypeEnum {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}
/**
  Pay菜单-Paid类型中的账单列表中的status属性枚举
  */
export enum PaidBillStatusEnum {
  MARK_AS_PAID = 'MARK_AS_PAID',
  PINVO_PAID = 'PINVO_PAID',
  SUPPOSED_TO_BE_PAID = 'SUPPOSED_TO_BE_PAID',
}
/**
  payment link 官方采集信息枚举
  */
export enum PaymentLinkOfficialCollectFieldEnum {
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
}
/**
  链接支付状态
  */
export enum PaymentLinkStatusEnum {
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
}
/**
  支付单展示状态
  */
export enum PaymentOrderShowStatusEnum {
  AUTHORIZING = 'AUTHORIZING',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  INIT = 'INIT',
  MULTI_AUTH_PROCESSING = 'MULTI_AUTH_PROCESSING',
  PAID = 'PAID',
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  SCHEDULED = 'SCHEDULED',
}
/**
  支付单状态
  */
export enum PaymentOrderStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  AUTHORIZING = 'AUTHORIZING',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  INIT = 'INIT',
  PAID = 'PAID',
  PAYING = 'PAYING',
  SCHEDULED = 'SCHEDULED',
}
/**
  支付类型
  */
export enum PaymentTypeEnum {
  BULK_PAYMENT = 'BULK_PAYMENT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  DOMESTIC_RECURRING_PAYMENT = 'DOMESTIC_RECURRING_PAYMENT',
  DOMESTIC_SCHEDULED_PAYMENT = 'DOMESTIC_SCHEDULED_PAYMENT',
  DOMESTIC_SINGLE_PAYMENT = 'DOMESTIC_SINGLE_PAYMENT',
}
/**
  可读错误码
  */
export enum PinvoResultCode {
  OPEN_BANKING_PAYMENT_RECORD_IS_NULL = 'OPEN_BANKING_PAYMENT_RECORD_IS_NULL',
  PINVO_ACCOUNTING_ALLOW_ACCESS_IS_NULL = 'PINVO_ACCOUNTING_ALLOW_ACCESS_IS_NULL',
  PINVO_ACCOUNTING_AUTH_CONFIRM_IS_NULL = 'PINVO_ACCOUNTING_AUTH_CONFIRM_IS_NULL',
  PINVO_ACCOUNTING_AUTH_RECORD_NOT_EXIST = 'PINVO_ACCOUNTING_AUTH_RECORD_NOT_EXIST',
  PINVO_ACCOUNTING_AUTH_RECORD_STATUS_NOT_AUTHED = 'PINVO_ACCOUNTING_AUTH_RECORD_STATUS_NOT_AUTHED',
  PINVO_ACCOUNTING_AUTH_RECORD_STATUS_TURN_EXCEPTION = 'PINVO_ACCOUNTING_AUTH_RECORD_STATUS_TURN_EXCEPTION',
  PINVO_ACCOUNTING_SOFTWARE_ILLEGAL = 'PINVO_ACCOUNTING_SOFTWARE_ILLEGAL',
  PINVO_ACCOUNT_NOT_BANK_ACCOUNT_TYPE = 'PINVO_ACCOUNT_NOT_BANK_ACCOUNT_TYPE',
  PINVO_ACCOUNT_NOT_EXIST = 'PINVO_ACCOUNT_NOT_EXIST',
  PINVO_ACCOUNT_NO_ILLEGAL = 'PINVO_ACCOUNT_NO_ILLEGAL',
  PINVO_ACCOUNT_NO_IS_BLANK = 'PINVO_ACCOUNT_NO_IS_BLANK',
  PINVO_ACCOUNT_STATUS_NOT_ACTIVE = 'PINVO_ACCOUNT_STATUS_NOT_ACTIVE',
  PINVO_AES_KEY_ID_REQUIRED = 'PINVO_AES_KEY_ID_REQUIRED',
  PINVO_AMOUNT_IS_NULL = 'PINVO_AMOUNT_IS_NULL',
  PINVO_AMOUNT_SHOULD_GREATER_THAN_ZERO = 'PINVO_AMOUNT_SHOULD_GREATER_THAN_ZERO',
  PINVO_APPLY_RESET_PASSWORD_PARAM_ILLEGAL = 'PINVO_APPLY_RESET_PASSWORD_PARAM_ILLEGAL',
  PINVO_ASSET_ID_IS_BLANK = 'PINVO_ASSET_ID_IS_BLANK',
  PINVO_ASSET_STATUS_ILLEGAL = 'PINVO_ASSET_STATUS_ILLEGAL',
  PINVO_ASSET_TARGET_TYPE_ILLEGAL = 'PINVO_ASSET_TARGET_TYPE_ILLEGAL',
  PINVO_AUTHED_ACCOUNTING_ALREADY_EXIST = 'PINVO_AUTHED_ACCOUNTING_ALREADY_EXIST',
  PINVO_AUTH_CODE_INCONSISTENCY = 'PINVO_AUTH_CODE_INCONSISTENCY',
  PINVO_AUTH_CODE_IS_BLANK = 'PINVO_AUTH_CODE_IS_BLANK',
  PINVO_BANK_ACCOUNT_CATEGORY_BIND_INFO_ILLEGAL = 'PINVO_BANK_ACCOUNT_CATEGORY_BIND_INFO_ILLEGAL',
  PINVO_BANK_ACCOUNT_NOT_EXIST = 'PINVO_BANK_ACCOUNT_NOT_EXIST',
  PINVO_BANK_ACCOUNT_TYPE_ILLEGAL = 'PINVO_BANK_ACCOUNT_TYPE_ILLEGAL',
  PINVO_BILL_CANNOT_CANCEL = 'PINVO_BILL_CANNOT_CANCEL',
  PINVO_BILL_CANNOT_MARKED_AS_PAID = 'PINVO_BILL_CANNOT_MARKED_AS_PAID',
  PINVO_BILL_CANNOT_MODIFIED = 'PINVO_BILL_CANNOT_MODIFIED',
  PINVO_BILL_CANNOT_PAY = 'PINVO_BILL_CANNOT_PAY',
  PINVO_BILL_COUNT_NOT_MATCH = 'PINVO_BILL_COUNT_NOT_MATCH',
  PINVO_BILL_ID_IS_BLANK = 'PINVO_BILL_ID_IS_BLANK',
  PINVO_BILL_NOT_EXIST = 'PINVO_BILL_NOT_EXIST',
  PINVO_BILL_PAYMENT_RECORD_ID_IS_BLANK = 'PINVO_BILL_PAYMENT_RECORD_ID_IS_BLANK',
  PINVO_BILL_REQUEST_ID_ILLEGAL = 'PINVO_BILL_REQUEST_ID_ILLEGAL',
  PINVO_BILL_SHOW_TYPE_IS_NULL = 'PINVO_BILL_SHOW_TYPE_IS_NULL',
  PINVO_BILL_SOURCE_IS_NULL = 'PINVO_BILL_SOURCE_IS_NULL',
  PINVO_BILL_STATUS_ILLEGAL = 'PINVO_BILL_STATUS_ILLEGAL',
  PINVO_BIZ_ID_IS_BLANK = 'PINVO_BIZ_ID_IS_BLANK',
  PINVO_BIZ_TYPE_ILLEGAL = 'PINVO_BIZ_TYPE_ILLEGAL',
  PINVO_CALL_EDEN_AI_FAILED = 'PINVO_CALL_EDEN_AI_FAILED',
  PINVO_CALL_OFFICIAL_ASSIGNED_EMAIL_FAILED = 'PINVO_CALL_OFFICIAL_ASSIGNED_EMAIL_FAILED',
  PINVO_CALL_OPEN_AI_FAILED = 'PINVO_CALL_OPEN_AI_FAILED',
  PINVO_CALL_PAI_ERROR = 'PINVO_CALL_PAI_ERROR',
  PINVO_CALL_UK_COMPANIES_HOUSE_FAILED = 'PINVO_CALL_UK_COMPANIES_HOUSE_FAILED',
  PINVO_CALL_UK_HMRC_HOUSE_FAILED = 'PINVO_CALL_UK_HMRC_HOUSE_FAILED',
  PINVO_CATEGORY_ALREADY_BINDED = 'PINVO_CATEGORY_ALREADY_BINDED',
  PINVO_CATEGORY_NAME_DUPLICATED_ARCHIVED_BANK_ACCOUNT = 'PINVO_CATEGORY_NAME_DUPLICATED_ARCHIVED_BANK_ACCOUNT',
  PINVO_CATEGORY_NAME_DUPLICATED_NON_BANK_ACCOUNT = 'PINVO_CATEGORY_NAME_DUPLICATED_NON_BANK_ACCOUNT',
  PINVO_CATEGORY_NAME_IS_BLANK = 'PINVO_CATEGORY_NAME_IS_BLANK',
  PINVO_CHANGE_PASSWORD_PARAM_ILLEGAL = 'PINVO_CHANGE_PASSWORD_PARAM_ILLEGAL',
  PINVO_CLIENT_IP_REQUIRED = 'PINVO_CLIENT_IP_REQUIRED',
  PINVO_CLIENT_RSA_PUBLIC_KEY_REQUIRED = 'PINVO_CLIENT_RSA_PUBLIC_KEY_REQUIRED',
  PINVO_CONTACT_CREATE_PARAM_ILLEGAL = 'PINVO_CONTACT_CREATE_PARAM_ILLEGAL',
  PINVO_CONTACT_DELETE_PARAM_ILLEGAL = 'PINVO_CONTACT_DELETE_PARAM_ILLEGAL',
  PINVO_CONTACT_DUPLICATE = 'PINVO_CONTACT_DUPLICATE',
  PINVO_CONTACT_EMAIL_IS_BLANK = 'PINVO_CONTACT_EMAIL_IS_BLANK',
  PINVO_CONTACT_FULL_NAME_IS_BLANK = 'PINVO_CONTACT_FULL_NAME_IS_BLANK',
  PINVO_CONTACT_ID_IS_BLANK = 'PINVO_CONTACT_ID_IS_BLANK',
  PINVO_CONTACT_MODIFY_PARAM_ILLEGAL = 'PINVO_CONTACT_MODIFY_PARAM_ILLEGAL',
  PINVO_CONTACT_NOT_EXIST = 'PINVO_CONTACT_NOT_EXIST',
  PINVO_CONTACT_SEARCH_PARAM_ILLEGAL = 'PINVO_CONTACT_SEARCH_PARAM_ILLEGAL',
  PINVO_CONTACT_STATUS_ILLEGAL = 'PINVO_CONTACT_STATUS_ILLEGAL',
  PINVO_CREATE_BILL_FROM_EMAIL_PARAM_ILLEGAL = 'PINVO_CREATE_BILL_FROM_EMAIL_PARAM_ILLEGAL',
  PINVO_CREATE_ORG_PARAM_ILLEGAL = 'PINVO_CREATE_ORG_PARAM_ILLEGAL',
  PINVO_CREATE_PAYMENT_LINK_ORDER_AMOUNT_ILLEGAL = 'PINVO_CREATE_PAYMENT_LINK_ORDER_AMOUNT_ILLEGAL',
  PINVO_CREATE_PAYMENT_LINK_ORDER_REQUEST_IS_NULL = 'PINVO_CREATE_PAYMENT_LINK_ORDER_REQUEST_IS_NULL',
  PINVO_CURRENCY_INCONSISTENCY = 'PINVO_CURRENCY_INCONSISTENCY',
  PINVO_CUSTOMER_NAME_IS_BLANK = 'PINVO_CUSTOMER_NAME_IS_BLANK',
  PINVO_DELETE_INVOICE_SYNCED_PINVO_AI_FAILED = 'PINVO_DELETE_INVOICE_SYNCED_PINVO_AI_FAILED',
  PINVO_EMAIL_DELIVER_RECORD_NOT_EXIST = 'PINVO_EMAIL_DELIVER_RECORD_NOT_EXIST',
  PINVO_EMAIL_ILLEGAL = 'PINVO_EMAIL_ILLEGAL',
  PINVO_EMAIL_NOT_OPEN_REGISTER = 'PINVO_EMAIL_NOT_OPEN_REGISTER',
  PINVO_EMAIL_TEMPLATE_NOT_EXIST = 'PINVO_EMAIL_TEMPLATE_NOT_EXIST',
  PINVO_ENCRYPT_CONFIG_RECORD_NOT_EXIST = 'PINVO_ENCRYPT_CONFIG_RECORD_NOT_EXIST',
  PINVO_FETCH_MEDIUM_UPLOAD_URL_PARAM_ILLEGAL = 'PINVO_FETCH_MEDIUM_UPLOAD_URL_PARAM_ILLEGAL',
  PINVO_GET_XERO_TOKEN_EXCEPTION = 'PINVO_GET_XERO_TOKEN_EXCEPTION',
  PINVO_GOCARDLESS_EXCEPTION = 'PINVO_GOCARDLESS_EXCEPTION',
  PINVO_HAS_NO_FULL_REFUND_ACCOUNT = 'PINVO_HAS_NO_FULL_REFUND_ACCOUNT',
  PINVO_INNER_DATA_ERROR = 'PINVO_INNER_DATA_ERROR',
  PINVO_INSTITUTION_ID_ILLEGAL = 'PINVO_INSTITUTION_ID_ILLEGAL',
  PINVO_INSTITUTION_NOT_EXIST = 'PINVO_INSTITUTION_NOT_EXIST',
  PINVO_INVOICE_BIZ_ITEM_INFOS_IS_EMPTY = 'PINVO_INVOICE_BIZ_ITEM_INFOS_IS_EMPTY',
  PINVO_INVOICE_BIZ_ITEM_INFO_DESCRIPTION_IS_BLANK = 'PINVO_INVOICE_BIZ_ITEM_INFO_DESCRIPTION_IS_BLANK',
  PINVO_INVOICE_BIZ_ITEM_PRICE_CURRENCY_ILLEGAL = 'PINVO_INVOICE_BIZ_ITEM_PRICE_CURRENCY_ILLEGAL',
  PINVO_INVOICE_BIZ_ITEM_PRICE_IS_NULL = 'PINVO_INVOICE_BIZ_ITEM_PRICE_IS_NULL',
  PINVO_INVOICE_BIZ_ITEM_QUANTITY_NOT_LARGER_THAN_ZERO = 'PINVO_INVOICE_BIZ_ITEM_QUANTITY_NOT_LARGER_THAN_ZERO',
  PINVO_INVOICE_DEST_STATUS_IS_BLANK = 'PINVO_INVOICE_DEST_STATUS_IS_BLANK',
  PINVO_INVOICE_DEST_STATUS_IS_ILLEGAL = 'PINVO_INVOICE_DEST_STATUS_IS_ILLEGAL',
  PINVO_INVOICE_DUE_DATE_IS_NULL = 'PINVO_INVOICE_DUE_DATE_IS_NULL',
  PINVO_INVOICE_ID_IS_BLANK = 'PINVO_INVOICE_ID_IS_BLANK',
  PINVO_INVOICE_ID_LIST_IS_EMPTY = 'PINVO_INVOICE_ID_LIST_IS_EMPTY',
  PINVO_INVOICE_ISSUED_DATE_IS_NULL = 'PINVO_INVOICE_ISSUED_DATE_IS_NULL',
  PINVO_INVOICE_NOT_EXIST = 'PINVO_INVOICE_NOT_EXIST',
  PINVO_INVOICE_NOT_SCHEDULED = 'PINVO_INVOICE_NOT_SCHEDULED',
  PINVO_INVOICE_NO_DUPLICATE = 'PINVO_INVOICE_NO_DUPLICATE',
  PINVO_INVOICE_NO_IS_BLANK = 'PINVO_INVOICE_NO_IS_BLANK',
  PINVO_INVOICE_PAYMENT_REQUEST_RECORD_NOT_EXIST = 'PINVO_INVOICE_PAYMENT_REQUEST_RECORD_NOT_EXIST',
  PINVO_INVOICE_PAYMENT_TIME_NOT_EXIST = 'PINVO_INVOICE_PAYMENT_TIME_NOT_EXIST',
  PINVO_INVOICE_PDF_FILE_ID_IS_BLANK = 'PINVO_INVOICE_PDF_FILE_ID_IS_BLANK',
  PINVO_INVOICE_SEND_TARGET_INFO_IS_EMPTY = 'PINVO_INVOICE_SEND_TARGET_INFO_IS_EMPTY',
  PINVO_INVOICE_SHOW_TYPE_ILLEGAL = 'PINVO_INVOICE_SHOW_TYPE_ILLEGAL',
  PINVO_INVOICE_STATUS_ILLEGAL = 'PINVO_INVOICE_STATUS_ILLEGAL',
  PINVO_INVOICE_STATUS_TURN_EXCEPTION = 'PINVO_INVOICE_STATUS_TURN_EXCEPTION',
  PINVO_INVOICE_SYNC_TO_PINVO_AI_FAILED = 'PINVO_INVOICE_SYNC_TO_PINVO_AI_FAILED',
  PINVO_INVOICE_SYNC_TO_PINVO_AI_STATUS_ILLEGAL = 'PINVO_INVOICE_SYNC_TO_PINVO_AI_STATUS_ILLEGAL',
  PINVO_IS_SCHEDULED_PAYMENT_IS_NULL = 'PINVO_IS_SCHEDULED_PAYMENT_IS_NULL',
  PINVO_KEY_WORLD_IS_BLANK = 'PINVO_KEY_WORLD_IS_BLANK',
  PINVO_LOGIN_ID_ALREADY_EXIST = 'PINVO_LOGIN_ID_ALREADY_EXIST',
  PINVO_LOGIN_ID_NOT_EXIST = 'PINVO_LOGIN_ID_NOT_EXIST',
  PINVO_MODIFY_BILL_NOT_ALLOWED = 'PINVO_MODIFY_BILL_NOT_ALLOWED',
  PINVO_MODIFY_ORG_PARAM_ILLEGAL = 'PINVO_MODIFY_ORG_PARAM_ILLEGAL',
  PINVO_NOTIFICATION_CONTENT_ASSEMBLER_NOT_EXIST = 'PINVO_NOTIFICATION_CONTENT_ASSEMBLER_NOT_EXIST',
  PINVO_NOTIFY_EMAIL_OPENED_PARAM_ILLEGAL = 'PINVO_NOTIFY_EMAIL_OPENED_PARAM_ILLEGAL',
  PINVO_NOT_REACH_SCHEDULED_PAYMENT_TIME = 'PINVO_NOT_REACH_SCHEDULED_PAYMENT_TIME',
  PINVO_OB_PAYMENT_STATUS_UPDATE_TIME_DISORDER = 'PINVO_OB_PAYMENT_STATUS_UPDATE_TIME_DISORDER',
  PINVO_OCR_INVOICE_APPLY_PARAM_ILLEGAL = 'PINVO_OCR_INVOICE_APPLY_PARAM_ILLEGAL',
  PINVO_OCR_INVOICE_FAILED = 'PINVO_OCR_INVOICE_FAILED',
  PINVO_OCR_INVOICE_MEDIUM_NOT_EXIST = 'PINVO_OCR_INVOICE_MEDIUM_NOT_EXIST',
  PINVO_OCR_INVOICE_MEDIUM_TYPE_NOT_SUPPORT = 'PINVO_OCR_INVOICE_MEDIUM_TYPE_NOT_SUPPORT',
  PINVO_OCR_INVOICE_PARAM_ILLEGAL = 'PINVO_OCR_INVOICE_PARAM_ILLEGAL',
  PINVO_OFFICIAL_ASSIGNED_EMAIL_NOT_EXIST = 'PINVO_OFFICIAL_ASSIGNED_EMAIL_NOT_EXIST',
  PINVO_OPERATION_ILLEGAL = 'PINVO_OPERATION_ILLEGAL',
  PINVO_ORG_CREATE_INVOICE_LIMIT_EXCEED = 'PINVO_ORG_CREATE_INVOICE_LIMIT_EXCEED',
  PINVO_ORG_ID_IS_BLANK = 'PINVO_ORG_ID_IS_BLANK',
  PINVO_ORG_LEGAL_NO_IS_BLANK = 'PINVO_ORG_LEGAL_NO_IS_BLANK',
  PINVO_ORG_MUST_HAVE_ONE_RECEIVING_BANK_ACCOUNT = 'PINVO_ORG_MUST_HAVE_ONE_RECEIVING_BANK_ACCOUNT',
  PINVO_ORG_NOT_EXIST = 'PINVO_ORG_NOT_EXIST',
  PINVO_ORG_NOT_MATCH = 'PINVO_ORG_NOT_MATCH',
  PINVO_ORG_OFFICIAL_ASSIGNED_EMAIL_GENERATE_FAILED = 'PINVO_ORG_OFFICIAL_ASSIGNED_EMAIL_GENERATE_FAILED',
  PINVO_ORG_RECEIVING_METHOD_NOT_EXIST = 'PINVO_ORG_RECEIVING_METHOD_NOT_EXIST',
  PINVO_PAGE_QUERY_PAGE_ILLEGAL = 'PINVO_PAGE_QUERY_PAGE_ILLEGAL',
  PINVO_PAGE_QUERY_PAGE_SIZE_ILLEGAL = 'PINVO_PAGE_QUERY_PAGE_SIZE_ILLEGAL',
  PINVO_PAID_TIME_IS_NULL = 'PINVO_PAID_TIME_IS_NULL',
  PINVO_PAYER_CONTACT_ID_ILLEGAL = 'PINVO_PAYER_CONTACT_ID_ILLEGAL',
  PINVO_PAYMENT_AMOUNT_ILLEGAL = 'PINVO_PAYMENT_AMOUNT_ILLEGAL',
  PINVO_PAYMENT_LINK_DELETED = 'PINVO_PAYMENT_LINK_DELETED',
  PINVO_PAYMENT_LINK_ID_IS_BLANK = 'PINVO_PAYMENT_LINK_ID_IS_BLANK',
  PINVO_PAYMENT_LINK_NOT_EXIST = 'PINVO_PAYMENT_LINK_NOT_EXIST',
  PINVO_PAYMENT_LINK_ORDER_CUSTOMIZED_FIELD_VALUES_ERROR = 'PINVO_PAYMENT_LINK_ORDER_CUSTOMIZED_FIELD_VALUES_ERROR',
  PINVO_PAYMENT_LINK_ORDER_NOT_EXIST = 'PINVO_PAYMENT_LINK_ORDER_NOT_EXIST',
  PINVO_PAYMENT_LINK_ORDER_OFFICIAL_FIELD_VALUES_ERROR = 'PINVO_PAYMENT_LINK_ORDER_OFFICIAL_FIELD_VALUES_ERROR',
  PINVO_PAYMENT_LINK_ORDER_TURN_STATUS_ERROR = 'PINVO_PAYMENT_LINK_ORDER_TURN_STATUS_ERROR',
  PINVO_PAYMENT_LINK_TITLE_ILLEGAL = 'PINVO_PAYMENT_LINK_TITLE_ILLEGAL',
  PINVO_PAYMENT_ORDER_ID_IS_BLANK = 'PINVO_PAYMENT_ORDER_ID_IS_BLANK',
  PINVO_PAYMENT_ORDER_NOT_EXIST = 'PINVO_PAYMENT_ORDER_NOT_EXIST',
  PINVO_PAYMENT_ORDER_STATUS_STILL_AUTHORIZING = 'PINVO_PAYMENT_ORDER_STATUS_STILL_AUTHORIZING',
  PINVO_PAYMENT_ORDER_STATUS_UPDATE_ILLEGAL = 'PINVO_PAYMENT_ORDER_STATUS_UPDATE_ILLEGAL',
  PINVO_PAYMENT_TYPE_ILLEGAL = 'PINVO_PAYMENT_TYPE_ILLEGAL',
  PINVO_PAY_AMOUNT_GREATER_THAN_BILL_AMOUNT = 'PINVO_PAY_AMOUNT_GREATER_THAN_BILL_AMOUNT',
  PINVO_PSU_ID_IS_BLANK = 'PINVO_PSU_ID_IS_BLANK',
  PINVO_QUERY_FILE_PARAM_ILLEGAL = 'PINVO_QUERY_FILE_PARAM_ILLEGAL',
  PINVO_QUERY_IMAGE_PARAM_ILLEGAL = 'PINVO_QUERY_IMAGE_PARAM_ILLEGAL',
  PINVO_QUERY_INVOICE_OCR_RESULT_PARAM_ILLEGAL = 'PINVO_QUERY_INVOICE_OCR_RESULT_PARAM_ILLEGAL',
  PINVO_QUERY_OPERATION_RECORD_BY_TARGET_PARAM_ILLEGAL = 'PINVO_QUERY_OPERATION_RECORD_BY_TARGET_PARAM_ILLEGAL',
  PINVO_QUERY_ORG_PARAM_ILLEGAL = 'PINVO_QUERY_ORG_PARAM_ILLEGAL',
  PINVO_RECORD_ID_IS_BLANK = 'PINVO_RECORD_ID_IS_BLANK',
  PINVO_REGISTER_FILE_PARAM_ILLEGAL = 'PINVO_REGISTER_FILE_PARAM_ILLEGAL',
  PINVO_REGISTER_IMAGE_PARAM_ILLEGAL = 'PINVO_REGISTER_IMAGE_PARAM_ILLEGAL',
  PINVO_REQUEST_ID_DUPLICATE = 'PINVO_REQUEST_ID_DUPLICATE',
  PINVO_REQUEST_ID_ILLEGAL = 'PINVO_REQUEST_ID_ILLEGAL',
  PINVO_REQUEST_IS_NULL = 'PINVO_REQUEST_IS_NULL',
  PINVO_REQUEST_PARAM_ILLEGAL = 'PINVO_REQUEST_PARAM_ILLEGAL',
  PINVO_RESET_PASSWORD_PARAM_ILLEGAL = 'PINVO_RESET_PASSWORD_PARAM_ILLEGAL',
  PINVO_RESET_PWD_TOKEN_ILLEGAL = 'PINVO_RESET_PWD_TOKEN_ILLEGAL',
  PINVO_RESET_PWD_TOO_FREQUENTLY = 'PINVO_RESET_PWD_TOO_FREQUENTLY',
  PINVO_SCHEDULED_PAYMENT_TIME_IS_NULL = 'PINVO_SCHEDULED_PAYMENT_TIME_IS_NULL',
  PINVO_SCHEDULED_PAYMENT_TIME_SHOULD_BE_FUTURE = 'PINVO_SCHEDULED_PAYMENT_TIME_SHOULD_BE_FUTURE',
  PINVO_SCHEDULED_SEND_TIME_IS_NULL = 'PINVO_SCHEDULED_SEND_TIME_IS_NULL',
  PINVO_SCHEDULED_TIME_IS_NULL = 'PINVO_SCHEDULED_TIME_IS_NULL',
  PINVO_SEND_EMAIL_PARAM_ILLEGAL = 'PINVO_SEND_EMAIL_PARAM_ILLEGAL',
  PINVO_SEND_IMMEDIATELY_IS_NULL = 'PINVO_SEND_IMMEDIATELY_IS_NULL',
  PINVO_SEND_INVOICE_PAYMENT_REQUEST_RECORD_NOT_EXIST = 'PINVO_SEND_INVOICE_PAYMENT_REQUEST_RECORD_NOT_EXIST',
  PINVO_SEND_OTP_WITH_EMAIL_PARAM_ILLEGAL = 'PINVO_SEND_OTP_WITH_EMAIL_PARAM_ILLEGAL',
  PINVO_SERVER_RSA_KEY_ID_REQUIRED = 'PINVO_SERVER_RSA_KEY_ID_REQUIRED',
  PINVO_SHORT_CODE_NOT_EXIST = 'PINVO_SHORT_CODE_NOT_EXIST',
  PINVO_SHORT_URL_CODE_IS_BLANK = 'PINVO_SHORT_URL_CODE_IS_BLANK',
  PINVO_SORT_CODE_ILLEGAL = 'PINVO_SORT_CODE_ILLEGAL',
  PINVO_SORT_CODE_IS_BLANK = 'PINVO_SORT_CODE_IS_BLANK',
  PINVO_STATE_IS_BLANK = 'PINVO_STATE_IS_BLANK',
  PINVO_SWITCH_SYNC_IS_NULL = 'PINVO_SWITCH_SYNC_IS_NULL',
  PINVO_TAX_RATE_ID_IS_BLANK = 'PINVO_TAX_RATE_ID_IS_BLANK',
  PINVO_TAX_RATE_NOT_EXIST = 'PINVO_TAX_RATE_NOT_EXIST',
  PINVO_UK_COMPANY_QUERY_PARAM_ILLEGAL = 'PINVO_UK_COMPANY_QUERY_PARAM_ILLEGAL',
  PINVO_UK_HMRC_QUERY_PARAM_ILLEGAL = 'PINVO_UK_HMRC_QUERY_PARAM_ILLEGAL',
  PINVO_UK_ORG_VAT_NO_CHECK_PARAM_ILLEGAL = 'PINVO_UK_ORG_VAT_NO_CHECK_PARAM_ILLEGAL',
  PINVO_UPLOAD_MEDIUM_FAILED = 'PINVO_UPLOAD_MEDIUM_FAILED',
  PINVO_USER_HAS_NO_ORG = 'PINVO_USER_HAS_NO_ORG',
  PINVO_USER_HAS_NO_PERMISSION = 'PINVO_USER_HAS_NO_PERMISSION',
  PINVO_USER_IS_FROZEN = 'PINVO_USER_IS_FROZEN',
  PINVO_USER_LOGIN_NOT_EXIST = 'PINVO_USER_LOGIN_NOT_EXIST',
  PINVO_USER_LOGIN_PARAM_ILLEGAL = 'PINVO_USER_LOGIN_PARAM_ILLEGAL',
  PINVO_USER_LOGIN_PWD_ERROR = 'PINVO_USER_LOGIN_PWD_ERROR',
  PINVO_USER_LOGIN_PWD_ERROR_TIMES_REACH_UPPER_LIMIT = 'PINVO_USER_LOGIN_PWD_ERROR_TIMES_REACH_UPPER_LIMIT',
  PINVO_USER_LOGIN_STATUS_ILLEGAL = 'PINVO_USER_LOGIN_STATUS_ILLEGAL',
  PINVO_USER_MODIFY_PARAM_ILLEGAL = 'PINVO_USER_MODIFY_PARAM_ILLEGAL',
  PINVO_USER_NOT_EXIST = 'PINVO_USER_NOT_EXIST',
  PINVO_USER_NOT_REGISTER_EMAIL_PWD_LOGIN = 'PINVO_USER_NOT_REGISTER_EMAIL_PWD_LOGIN',
  PINVO_USER_NO_PERMISSION_OPERATE_ORG = 'PINVO_USER_NO_PERMISSION_OPERATE_ORG',
  PINVO_USER_ORG_ROLE_DUPLICATE = 'PINVO_USER_ORG_ROLE_DUPLICATE',
  PINVO_USER_QUERY_PARAM_ILLEGAL = 'PINVO_USER_QUERY_PARAM_ILLEGAL',
  PINVO_USER_REGISTER_PARAM_ILLEGAL = 'PINVO_USER_REGISTER_PARAM_ILLEGAL',
  PINVO_VALIDATE_EMAIL_OTP_PARAM_ILLEGAL = 'PINVO_VALIDATE_EMAIL_OTP_PARAM_ILLEGAL',
  PINVO_VALIDATE_OTP_FAILED = 'PINVO_VALIDATE_OTP_FAILED',
  PINVO_VALIDATE_OTP_REQUEST_ID_ILLEGAL = 'PINVO_VALIDATE_OTP_REQUEST_ID_ILLEGAL',
  PINVO_VERIFY_EMAIL_PARAM_ILLEGAL = 'PINVO_VERIFY_EMAIL_PARAM_ILLEGAL',
  PINVO_XERO_ACCOUNT_INCONSISTENCY = 'PINVO_XERO_ACCOUNT_INCONSISTENCY',
  PINVO_XERO_ORG_AUTHED_ALREADY_EXISTS = 'PINVO_XERO_ORG_AUTHED_ALREADY_EXISTS',
  PINVO_XERO_STATE_INCONSISTENCY = 'PINVO_XERO_STATE_INCONSISTENCY',
}
/**
  税率列表
  */
export enum TaxRateEnum {
  NO_VAT = 'NO_VAT',
  REDUCED_VAT = 'REDUCED_VAT',
  STANDARD_VAT = 'STANDARD_VAT',
  ZERO_VAT = 'ZERO_VAT',
}
/**
  税信息的来源：pinvo初始化 或 从会计软件导入
  */
export enum TaxRateSourceEnum {
  PINVO = 'PINVO',
  QB = 'QB',
  SAGE = 'SAGE',
  XERO = 'XERO',
}
/**
  税率状态
  */
export enum TaxRateStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
  UNKNOWN = 'UNKNOWN',
}
/**
  用户状态
  */
export enum UserStatusEnum {
  FROZEN = 'FROZEN',
  INACTIVE = 'INACTIVE',
  NORMAL = 'NORMAL',
  REMOVED = 'REMOVED',
}
