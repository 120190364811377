


export const layouts = {
'404': () => import('/root/workspace/pinvo-frontend_2rcD/src/layouts/404.vue'),
'home': () => import('/root/workspace/pinvo-frontend_2rcD/src/layouts/home.vue'),
'normal': () => import('/root/workspace/pinvo-frontend_2rcD/src/layouts/normal.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'normal'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
