<script lang="ts" setup>
import type { MessageOptions, MessageRenderMessage } from 'django-naive-ui'
import { NAlert } from 'django-naive-ui'
import { PinvoResultCode } from '~/enums/swagger'
import { createNamespace } from '~/utils/bem'

const emits = defineEmits(['placement'])

const [name] = createNamespace('message')

const nMessage = useMessage()

const { getResultMessage, t } = useLocalMessage()
const resultCodeList = Object.keys(PinvoResultCode)

const renderMessage: MessageRenderMessage = (props) => {
  const { type } = props
  return h(
    NAlert,
    {
      closable: props.closable,
      onClose: props.onClose,
      type: type === 'loading' ? 'default' : type,
      style: {
        boxShadow: 'var(--n-box-shadow)',
        maxWidth: 'calc(100vw - 32px)',
        width: '480px',
      },
    },
    {
      default: () => props.content,
    },
  )
}

const create = (content: string, options?: MessageOptions | undefined, placement?: string) => {
  if (resultCodeList.includes(content)) {
    const { fullText } = getResultMessage(content)
    // 没有写报错文案
    if (fullText === content)
      content = 'Sorry, some error happened'
    else
      content = fullText
  }
  else {
    content = t(content)
  }
  if (placement)
    emits('placement', placement)
  nMessage.create(content || 'undefined', { ...options })
}

defineExpose({ create })
</script>

<template>
  <div :class="[name]" />
</template>

<style lang="less" scoped>
    .pinvo-message {

    }
</style>
