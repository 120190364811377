import { acceptHMRUpdate, defineStore } from 'pinia'
import { orgModifyApi, orgQueryOwnedOrgApi } from '~/apis/org'
import type { OrgInfo, OrgModifyRequest } from '~/apis/swagger/apiModel'
import { CacheKey } from '~/enums/cache'

export const useOrgStore = defineStore('org', () => {
  const orgInfo = getLocal<OrgInfo>(CacheKey.ORG_INFO)

  const orgId = computed(() => {
    return orgInfo.value?.orgId || ''
  })
  const fetchUpdateOrgInfo = async () => {
    const res = await orgQueryOwnedOrgApi()
    orgInfo.value = res
    Sentry.setContext('org', {
      ...res,
    })
  }

  const modifyOrgInfo = async (data: OrgModifyRequest) => {
    const res = await orgModifyApi(data)
    orgInfo.value = res
  }

  const clearState = () => {
    orgInfo.value = null
  }

  return {
    orgInfo,
    orgId,
    clearState,
    modifyOrgInfo,
    fetchUpdateOrgInfo,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useOrgStore, import.meta.hot))
