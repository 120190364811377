import type { NavigationGuardWithThis, Router } from 'vue-router'
import { RoutePath } from '~/enums/path'
import { VerificationStatusEnum } from '~/enums/swagger'

/**
 * 判断是否已经验收集过公司信息
 * 1. 如果已经经验收集过公司信息则不得再次进入
 * 2. 如果还未收集需要强制引导
 * @param router
 */
export const setupOrgGuard = (router: Router) => {
  router.beforeEach((to, from, next) => {
    const userStore = useUserStore()
    const orgStore = useOrgStore()
    if (userStore.token && to.path !== RoutePath.OrgCreate && userStore.userInfo?.emailVerificationStatus === VerificationStatusEnum.PASS_VERIFICATION) {
      if (!orgStore.orgInfo?.orgName) {
        next(RoutePath.OrgCreate)
        return
      }
    }
    next()
  })
}

// 进入手机公司页面路由守卫，防止重复进入
export const enterOrgCreateGuard: NavigationGuardWithThis<undefined> = (to, form, next) => {
  const orgStore = useOrgStore()
  if (orgStore.orgInfo?.orgName) {
    next(RoutePath.Base)
    return
  }
  next()
}
