import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'
import { CacheKey } from '~/enums/cache'
import { RoutePath } from '~/enums/path'
import { getRouterIns } from '~/router'

export const useAppStore = defineStore('app', () => {
/**
   * 缓存页面
   */
  const keepAliveCache = getLocal<string[]>(CacheKey.KEEP_ALIVE_PAGES, [])
  const firstLogin = ref(true)

  const userStore = useUserStore()
  const orgStore = useOrgStore()
  const gtm = useGtm()

  const addKeepAliveComponent = (name: string) => {
    const has = keepAliveCache.value.includes(name)
    if (!has)
      keepAliveCache.value.push(name)
  }

  const removeKeepAliveComponent = (name: string) => {
    const index = keepAliveCache.value.indexOf(name)
    if (index >= 0)
      return keepAliveCache.value.splice(index, 1)

    return ''
  }

  const setupMustData = async () => {
    await userStore.fetchUpdateUserInfo()
    await orgStore.fetchUpdateOrgInfo()
    if (userStore.userInfo.userId) {
      if (gtm?.enabled()) {
        window.dataLayer?.push({
          event: 'auto_login',
          user_id: userStore.userInfo.userId,
        })
      }
    }
  }

  const clearAllState = () => {
    firstLogin.value = true
    userStore.clearState()
    nextTick(() => {
      manualSettingLocal()
    })
  }
  const clearBusinessState = () => {
    orgStore.clearState()
  }

  const clearStateAndGoLogin = (redirect?: string) => {
    clearAllState()
    getRouterIns().push({ path: RoutePath.Login, query: { redirect } })
  }

  return { clearStateAndGoLogin, clearAllState, clearBusinessState, setupMustData, addKeepAliveComponent, removeKeepAliveComponent, keepAliveCache, firstLogin }
})
