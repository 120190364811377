import { defineStore } from 'pinia'
import { ref } from 'vue'
import { fetchServerAesKeyApi, fetchServerRsaPublicKeyApi, queryServerAesKeyByAesKeyIdApi } from '~/apis/encrypt'
import { getRsaKeys, rsaDecode } from '~/utils/encrypt'

export const useSecurityStore = defineStore('security', () => {
  const clientRsa = ref<{ privateKey: CryptoKey; publicKey: CryptoKey; privateKeyStr: string; publicKeyStr: string }>()
  const serverAesKey = ref('')
  const serverAesKeyId = ref('')
  const serverAesKeyCache = ref<Record<string, string>>({})

  const getServerAesKey = async () => {
    const clientRsaInfo = await getRsaKeys()
    if (clientRsaInfo) {
      const rsa_res = await fetchServerRsaPublicKeyApi()
      clientRsa.value = clientRsaInfo
      const aes_res = await fetchServerAesKeyApi(clientRsaInfo.publicKeyStr, rsa_res.rsaKeyId!)
      try {
        const aesKey = await rsaDecode(clientRsaInfo.privateKey, aes_res.encryptedAesKey!)
        if (aesKey) {
          serverAesKey.value = aesKey
          serverAesKeyId.value = aes_res.aesKeyId!
        }
      }
      catch (error) {
        console.error(error)
      }
    }
  }

  const getServerAesKeyByAesKeyId = async (serverAesKeyId: string) => {
    // aes key 缓存， 防止多次重复请求
    const cache = serverAesKeyCache.value[serverAesKeyId]
    if (cache)
      return cache

    // FIXME: clientRsa 可能为空，必须在路由前置获取到该信息
    if (clientRsa.value) {
      const aesRes = await queryServerAesKeyByAesKeyIdApi(serverAesKeyId, clientRsa.value.publicKeyStr)
      const aesKey = await rsaDecode(clientRsa.value.privateKey, aesRes.encryptedAesKey!)
      consola.info(`解密服务端获取的 aesKey 加密数据: ${aesKey}`)
      serverAesKeyCache.value[serverAesKeyId] = aesKey || ''
      return aesKey
    }
  }

  const resetState = () => {
    clientRsa.value = undefined
    serverAesKey.value = ''
  }
  return { clientRsa, serverAesKeyId, serverAesKeyCache, serverAesKey, getServerAesKey, resetState, getServerAesKeyByAesKeyId }
})
