import { bignumber } from './math'
import type { MoneyInfo } from '~/apis/swagger/apiModel'
import { CurrencyCodeEnum, CurrencySymbol } from '~/enums/currency'

export const getCurrency = (c?: string) => {
  if (!c)
    return '*'
  if (CurrencySymbol[c])
    return CurrencySymbol[c]
  const k = Object.keys(CurrencyCodeEnum).find(e => CurrencySymbol[e] === c)
  // 如果传的是 符号 则返回code
  if (k)
    return k

  return c
}

export const formatMoney = (amount?: MoneyInfo) => {
  if (!amount)
    return ''
  return `${getCurrency(amount.currency)}${bignumber(amount.amount).toFixed(2)}`
}
