import type { Router } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { setupEnterGuard } from './guards/enterGuard'
import { setupGuard } from './guards/guard'
import { staticRoutes } from './staticRoutes'
import { CacheKey } from '~/enums/cache'
import { getQueryByUrl } from '~/utils/dom'

let routerIns: Router

export const setupRouter = () => {
  setupEnterGuard(routerOptions.value)
  routerIns = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [...staticRoutes, ...routerOptions.value],
    parseQuery: (search) => {
      return getQueryByUrl(search, { decode: true })
    },
  })

  const storageValue = getLocal<string[]>(CacheKey.KEEP_ALIVE_PAGES, [])

  const caches = routerOptions.value.reduce((pre, curr) => {
    if (!curr.children)
      return pre
    return pre.concat(curr.children.filter(e => e.meta?.keepAlive).map(e => `${e.name?.toString()}`))
  }, [] as string[])

  storageValue.value = caches

  setupGuard(routerIns)

  return routerIns
}

export const getRouterIns = () => {
  return routerIns
}
