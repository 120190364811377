// import Previewer from 'virtual:vue-component-preview'
import { ViteSSG } from 'vite-ssg/single-page'
import App from './App.vue'
import { setupRouter } from './router'
import type { UserModule } from './types'

import 'virtual:windi-base.css'
import 'virtual:windi-components.css'
import 'virtual:windi-devtools'
import 'virtual:windi-utilities.css'
import './styles/main.css'
import { CacheKey } from './enums/cache'
import { getEmbedPromise, setEmbedPromise } from './singleton/embed'
import { Deferred } from './utils/deferred'

const isEmbed = window.location.search.includes('embed=true')

if (isEmbed)
  setEmbedPromise(new Deferred())

window.addEventListener('message', (e) => {
  consola.info('token', e)
  if (e.data.type === 'login') {
    const token = getLocal<string>(CacheKey.TOKEN, '')
    consola.info('token v', token)
    token.value = e.data.payload.token
    getEmbedPromise().resolve('')
  }
})

if (window.parent)
  window.parent.postMessage({ type: 'onload' }, '*')

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  (ctx) => {
    if (ctx.isClient) {
      const router = setupRouter()
      ctx.app.use(router)
      // install all modules under `modules/`
      Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
        .forEach(i => i.install?.({ ...ctx, router }))
    }

    // ctx.app.use(Previewer)
  },
)

consola.log(`  
  _   _                            _  __          __        _        
 | \\ | |                          | | \\ \\        / /       | |       
 |  \\| | ___  _ __ _ __ ___   __ _| |  \\ \\  /\\  / /__  _ __| | _____ 
 | . \` |/ _ \\| '__| '_ \` _ \\ / _\` | |   \\ \\/  \\/ / _ \\| '__| |/ / __|
 | |\\  | (_) | |  | | | | | | (_| | |    \\  /\\  / (_) | |  |   <\\__ \\
 |_| \\_|\\___/|_|  |_| |_| |_|\\__,_|_|     \\/  \\/ \\___/|_|  |_|\\_\\___/
                                                                     
`)
