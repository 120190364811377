import { ApiEnum } from './swagger/apiEnum'
import type { EmailVerifyRequest, LoginInfo, PasswordChangeRequest, PasswordResetApplyRequest, PasswordResetRequest, SendEmailOtpRequest, UserInfo, UserLoginWithPwdRequest, UserModifyRequest, UserRegisterRequest } from './swagger/apiModel'

export const userRegisterWithPwdAndLoginApi = async (data: UserRegisterRequest) => {
  return await http.post<LoginInfo>(ApiEnum.UserRegisterWithPwdAndLogin, {
    data,
  })
}
export const userLogoutApi = async () => {
  return await http.post(ApiEnum.Logout, {
    data: {},
  })
}
export const otpSendEmailApi = async (data: SendEmailOtpRequest) => {
  return await http.post(ApiEnum.OtpSendEmail, {
    data,
  })
}
export const userVerifyEmailApi = async (data: EmailVerifyRequest) => {
  return await http.post(ApiEnum.UserVerifyEmail, {
    data,
  })
}
export const userQueryApi = async () => {
  return await http.post<UserInfo>(ApiEnum.UserQuery, {
    data: {},
  })
}
export const loginWithPwdApi = async (data: UserLoginWithPwdRequest) => {
  return await http.post<LoginInfo>(ApiEnum.LoginLoginWithPwd, {
    data,
  })
}

export const userModifyApi = async (data: UserModifyRequest) => {
  return await http.post<UserInfo>(ApiEnum.UserModify, {
    data,
  })
}

export const userPasswordChangeApi = async (data: PasswordChangeRequest) => {
  return await http.post(ApiEnum.UserChangePassword, {
    data,
  })
}

export const userResetPasswordApi = async (data: PasswordResetRequest) => {
  return await http.post(ApiEnum.UserResetPassword, {
    data,
  })
}

export const userApplyResetPasswordApi = async (data: PasswordResetApplyRequest) => {
  return await http.post(ApiEnum.UserApplyResetPassword, {
    data,
  })
}
