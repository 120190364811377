import { isUndefined } from 'lodash-es'
import type { PinvoResultCode } from '~/enums/swagger'

export function pxToRem(px: number, fz = 16, unitPrecision = 1) {
  return `${toFixed((px / fz) * 100, unitPrecision)}rem`
}

export function pxToRemNumber(px: number, fz = 16, unitPrecision = 1) {
  return toFixed((px / fz) * 100, unitPrecision)
}

function toFixed(n: number, precision: number) {
  const multiplier = 10 ** (precision + 1)
  const wholeNumber = Math.floor(n * multiplier)
  return (Math.round(wholeNumber / 10) * 10) / multiplier
}

export const sleep = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, time)
  })
}

export const noopFn: Fn = () => {}

/**
 * 获取字符数
 * @param val
 * @returns
 */
export const getCharLen = (val = '') => {
  let len = 0

  for (let i = 0; i < val.length; i++) {
    const a = val.charAt(i)

    // eslint-disable-next-line no-control-regex
    if (a.match(/[^\x00-\xFF]/gi) != null)
      len += 2

    else
      len += 1
  }

  return len
}

export const getResponseErrorCode = (e: any): PinvoResultCode => {
  return e?.response?.data?.code
}

export const assign = (target: any, source: any, option: { pick?: boolean } = {}) => {
  const { pick } = option
  if (pick) {
    Object.keys(target).forEach((k) => {
      if (!isUndefined(source[k]))
        target[k] = source[k]
    })
    return target
  }
  return Object.assign(target, source)
}

export const copyToClip = (content: string) => {
  const aux = document.createElement('textarea')
  // aux.setAttribute('value', content);
  aux.value = content
  aux.setAttribute('style', 'display:hide')
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
}
