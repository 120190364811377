import { type UserModule } from '~/types'

export const install: UserModule = ({ isClient }) => {
  if (isClient) {
    // router?.beforeEach((to, from) => {
    //   if (to.path !== from.path)
    //     NProgress.start()
    // })
    // router?.afterEach(() => { NProgress.done() })
  }
}
