import type { MaybeComputedRef, RemovableRef } from '@vueuse/core'
import { isClient } from '@vueuse/core'
import { isUndefined } from 'lodash-es'

const normal_key = 'normal'
const persistence_key = 'persistence'

const localData = useLocalStorage<{ version?: string; [key: string]: any }>(getStorageShortName(), { version: globSetting.version, normal: {}, persistence: {} }, { deep: false, mergeDefaults: true })

const normalStorage = reactive(localData.value[normal_key])
const persistenceStorage = localData.value[persistence_key]

export const getLocal = <T>(key: string, initData?: MaybeComputedRef<T>) => {
  const value = toRef(normalStorage, key)
  if (isUndefined(value.value))
    value.value = initData
  return value as RemovableRef<T>
}

export const manualSettingLocal = () => {
  localData.value = { version: globSetting.version, normal: normalStorage, persistence: persistenceStorage }
  // consola.log(`手动存储：${JSON.stringify(localData.value)}`)
}

export const clearStorage = (options: { persistence?: boolean } = {}) => {
  localData.value = { version: globSetting.version, normal: {}, persistence: options.persistence ? {} : persistenceStorage }
}

if (isClient) {
  // 防止更改 localstorage 数据
  (() => {
    // /** Write to local before closing window */
    window.addEventListener('beforeunload', () => {
      manualSettingLocal()
    })

    // 手机端兼容 beforeunload
    window.addEventListener('pagehide', () => {
      manualSettingLocal()
    })
  })()
}
