import type { Pinia } from 'pinia'
import { useRoute } from 'vue-router'
import { useGlobSetting } from './setting'
import { aesDecrypt, aesEncrypt } from '~/utils/encrypt'
import { QueryKeyEnum } from '~/enums/query'

// const { Sentry } = useSentry()

export const useAesEncryptHook = (pinia?: Pinia) => {
  const securityStore = useSecurityStore(pinia)
  const { openAesEncrypt } = useGlobSetting()
  const encrypt = (word: string) => {
    if (!openAesEncrypt)
      return word
    return aesEncrypt(word, securityStore.serverAesKey)
  }

  /**
   * 异步获取服务端 aesKey 进行加密
   * @param word
   * @param serverAesKeyId
   * @returns
   */
  const asyncEncrypt = async (word: string, serverAesKeyId?: string) => {
    if (!openAesEncrypt)
      return word
    let serverAesKey = securityStore.serverAesKey
    // 如果传了id，则使用id获取服务端的 aes key
    if (serverAesKeyId) {
      const aesKey = await securityStore.getServerAesKeyByAesKeyId(serverAesKeyId)
      if (aesKey)
        serverAesKey = aesKey
    }
    return aesEncrypt(word, serverAesKey)
  }

  const decrypt = async (word: string, serverAesKeyId?: string) => {
    if (!openAesEncrypt)
      return word
    let serverAesKey = securityStore.serverAesKey
    // 如果传了id，则使用id获取服务端的 aes key
    if (serverAesKeyId) {
      const aesKey = await securityStore.getServerAesKeyByAesKeyId(serverAesKeyId)
      if (aesKey)
        serverAesKey = aesKey
    }
    consola.info(`解密 word：${word}`)
    return aesDecrypt(word, serverAesKey)
  }

  return { encrypt, decrypt, asyncEncrypt, securityStore }
}

export const useAesDecryptByRouteQuery = () => {
  const route = useRoute()
  const { decrypt: decode, encrypt } = useAesEncryptHook()
  const decryptValCache = {} as Record<string, string>

  const decrypt = async (queryKey: string) => {
    const query = route.query as Record<string, string>
    const val = query[queryKey]
    // 缓存
    const cache = decryptValCache[val]
    if (cache)
      return cache

    const nw_k = query[QueryKeyEnum.NW_K]
    try {
      if (!val)
        return ''
      const decryptVal = await decode(val, nw_k)
      consola.info(`解密${queryKey}:`, decryptVal)
      decryptValCache[val] = decryptVal
      return decryptVal
    }
    catch (error: any) {
    //   Sentry.captureException('路由参数解密失败：', { extra: { error, query, val } })
    }
    return ''
  }

  const useDecrypt = (queryKey: string) => {
    const q = ref('')
    decrypt(queryKey).then((res) => {
      q.value = res
    })
    return q
  }

  return { decrypt, useDecrypt, encrypt }
}
