import { createNiceAxios } from 'nice-axios'
import { aesDecryptPlugin, aesEncryptPlugin } from './plugins/aesEncryptPlugin'
import { tokenPlugin } from './plugins/tokenPlugin'
import { getCurrFullPath } from '~/utils/dom'

export const createHttp = () => {
  // create. first
  createNiceAxios([
    { desc: 'aes加密', order: -980, executor: aesEncryptPlugin },
    { desc: '请求token', order: -960, executor: tokenPlugin },
    // 后置
    { desc: 'aes解密', order: 1060, executor: aesDecryptPlugin },

  ], {
    baseURL: globSetting.apiUrl,
    afterPluginOption: {
      successCode: 'SUCCESS',
      checkErrorCode: (code, message, res) => {
        consola.error(res)
      },
      checkHttpErrorCode: (error, meta) => {
        consola.error(error)
        const appStore = useAppStore()
        if (typeof error === 'string') {
          // 一些网络错误等
        }
        else {
          const { create } = useNwMessage()
          switch (error.response?.status) {
            case 401:
              appStore.clearStateAndGoLogin(getCurrFullPath())
              break
            case 400:
              if (meta?.showErrorTip) {
                if (error.response.data!.code === 'PINVO_USER_NO_PERMISSION_OPERATE_ORG')
                  return
                // 提示
                create(error.response.data!.code, { type: 'error' })
              }
              break
            case 500:
              create(`server error: ${error.response.data!.code}`, { type: 'error' })
              break
            default:
              break
          }
        }
      },
    },
  })
}
