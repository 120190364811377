import type { Router } from 'vue-router'

export const createEncryptGuard = (router: Router) => {
  router.beforeEach(async (_, __, next) => {
    const securityStore = useSecurityStore()
    if (!securityStore.serverAesKey && globSetting.openAesEncrypt)
      await securityStore.getServerAesKey()

    next()
  })
}
