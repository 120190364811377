import { type UserModule } from '~/types'
import { division, minus, multiplied, plus } from '~/utils/math'
import { formatMoney, getCurrency } from '~/utils/tool'

export const install: UserModule = ({ isClient, app }) => {
  if (!isClient)
    return

  app.config.globalProperties.$getCurrency = getCurrency
  app.config.globalProperties.$formatMoney = formatMoney
  app.config.globalProperties.$plus = plus
  app.config.globalProperties.$minus = minus
  app.config.globalProperties.$division = division
  app.config.globalProperties.$multiplied = multiplied
}
