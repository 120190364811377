import { BrowserTracing } from '@sentry/tracing'
import { GlobalHandlers } from '@sentry/vue'

import type { App } from 'vue'
import { type UserModule } from '~/types'

const initVueErrorHandler = (app: App) => {
  app.config.errorHandler = (err, instance, info) => {
    // 处理错误，例如：报告给一个服务
    Sentry.captureException(err, { tags: { errorType: 'vue' }, extra: { 'vue-error-info': info } })
  }
}

const initDomGlobalErrorHandler = () => {
  window.onerror = (message, source, lineno, colno, error) => {
    Sentry.captureException(error || new Error(message.toString()), { tags: { errorType: 'dom' }, extra: { 'dom-source': source } })
  }

  // 监听 promise 错误
  window.addEventListener('unhandledrejection', async (event) => {
    Sentry.captureException(event?.reason, { tags: { errorType: 'promise' } })
  })
}

export const install: UserModule = ({ isClient, app, router }) => {
  const { sentryDsn, version, openSentry } = globSetting
  if (isClient && openSentry) {
    Sentry.init({
      app,
      dsn: sentryDsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          // tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
        }),
        new GlobalHandlers({ onerror: false, onunhandledrejection: false }),
      ],
      tracesSampleRate: 1.0,
      environment: 'production',
      release: `@${version}`,
      // logLevel: 3,

      // debug: !isProdMode(),
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,
    })

    initVueErrorHandler(app)
    initDomGlobalErrorHandler()
  }
}
