import type { Router } from 'vue-router'
import { RoutePath } from '~/enums/path'
import { getEmbedPromise } from '~/singleton/embed'

export const setupPermissionGuard = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    const embedPromise = getEmbedPromise()
    if (embedPromise)
      await embedPromise.promise
    const userStore = useUserStore()
    const appStore = useAppStore()
    // 如果有token并且路由到登录页面时，强制导向 dashboard
    if (to.path === RoutePath.Login && userStore.token) {
      next(RoutePath.Base)
      return
    }
    if (to.meta.ignorePermission) {
      next()
      return
    }

    // 第一次需要进行的必要接口
    if (appStore.firstLogin && userStore.token) {
      try {
        // 如果是第一次登陆这里会再次调用用户信息
        await appStore.setupMustData()
        appStore.firstLogin = false
      }
      catch (error: any) {
        if (error?.response?.status !== 400)
          appStore.clearStateAndGoLogin()
        return next(false)
      }
    }

    // 有token或者不需要权限的路由可以放行
    if (userStore.token) {
      next()
      return
    }
    // 没有权限则进入到登陆页面获取token
    const _redirect_path = from.path !== to.path ? to.fullPath : undefined
    next({ path: RoutePath.Login, query: { _redirect_path } })
  })

  router.afterEach((to, _) => {
    const appStore = useAppStore()
    if (to.name === RoutePath.Login)
      appStore.clearBusinessState()
  })
}
