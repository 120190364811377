<script lang="ts" setup>
/**
   * @author django
   * @date 2022年03月04日
   * 自定义Popup组件
   */
import { computed, nextTick, ref, watch } from 'vue'

interface IProps {
  position?: string | 'bottom' | 'right' | 'left' | 'center'
  // 显示弹窗
  show: boolean
  // 遮罩是否可点击
  maskable?: boolean

  // 层级
  zIndex?: number

  // 自定义类
  className?: string

  // 遮罩自定义类
  maskClassName?: string

  // 内容容器自定义类
  contentClassName?: string

  contentStyle?: any

  // 是否显示打开动画
  showOpenAnimation?: boolean

  // 是否开启关闭动画
  showCloseAnimation?: boolean

  // 开启默认动画 mask 动画 渐隐
  showMaskAnimation?: boolean

  // 内容背景颜色
  contentBgColor?: string

  immediate?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  position: 'bottom',
  maskable: true,
  maskClassName: '',
  showOpenAnimation: true,
  showCloseAnimation: true,
  contentClassName: '',
  contentBgColor: '#fff',
  contentStyle: {},
})

const emits = defineEmits(['update:show', 'beforeOpen', 'afterOpen', 'beforeClose', 'afterClose'])

// ref 声明

const xccPopupRef = ref()

const { doAnima, translateX, translateY } = useAnimation()

const _classNames = computed(() => {
  let temp = ''
  switch (props.position) {
    case 'bottom':
      temp = 'absolute bottom-0 w-full'
      break
    case 'top':
      temp = 'absolute top-0 w-full'
      break
    case 'center':
      break
    default:
      break
  }
  return `${props.className || ''} ${temp}`
})

const { start, stop } = useTimeoutFn(async () => {
  if (props.show)
    emits('afterOpen')

  else
    emits('afterClose')
}, 400, { immediate: false })

const rect = useElementBounding(xccPopupRef)

// computed

const _maskClassName = computed(() => {
  switch (props.position) {
    case 'center':
      return ' flex items-center justify-center flex-col'
  }
  return ''
})

const popupContentClass = computed(() => {
  let temp = ''
  switch (props.position) {
    case 'bottom':
    case 'top':
      temp = ' '
      break
    case 'center':
      temp = ' '
      break
    default:
      temp = ' absolute'
      break
  }
  return `${props.contentClassName || ''} ${temp}`
})

watch(
  () => props.show,
  (newVal) => {
    if (newVal)
      emits('beforeOpen')

    else
      emits('beforeClose')

    stop()
    start()
    makeAnima(newVal)
  },
  { immediate: props.immediate },
)

function makeAnima(show: boolean) {
  if (show) {
    if (!props.showOpenAnimation || props.showMaskAnimation)
      return
    nextTick(() => {
      switch (props.position) {
        case 'top':
          translateY(xccPopupRef.value, 400, ['-100%', '0'])
          break
        case 'bottom':
          translateY(xccPopupRef.value, 400)
          break
        case 'right':
          translateX(xccPopupRef.value, 400, ['100%', `${rect.width.value}px`])
          break
        case 'center':
          doAnima(xccPopupRef.value, 400, { scale: [0.5, 1], opacity: [0, 1], easing: 'easeInOutBack' })
          break
        default:
          translateY(xccPopupRef.value, 400)
          break
      }
    })
  }
  else {
    if (!props.showCloseAnimation || props.showMaskAnimation)
      return
    nextTick(() => {
      switch (props.position) {
        case 'top':
          translateY(xccPopupRef.value, 400, ['0', '-100%'])
          break
        case 'bottom':
          translateY(xccPopupRef.value, 400, ['0', '100%'])
          break
        case 'right':
          translateX(xccPopupRef.value, 400, [`${rect.width.value}px`, '100%'])
          break
        case 'center':
          doAnima(xccPopupRef.value, 400, { scale: [1, 0.5], opacity: [1, 0], easing: 'easeInOutBack' })
          break
        default:
          translateY(xccPopupRef.value, 400, ['0', '100%'])
          break
      }
    })
  }
}
</script>

<template>
  <div>
    <NwMask
      :show="show"
      :z-index="zIndex"
      :maskable="maskable"
      :class-name="maskClassName + _maskClassName"
      :show-content-animation="showMaskAnimation"
      @update:show="$emit('update:show', $event)"
    >
      <div ref="xccPopupRef" class="xcc-popup-container" :class="_classNames">
        <div class="xcc-popup-content overflow-y-auto" :class="popupContentClass" :style="{ backgroundColor: contentBgColor, ...contentStyle }">
          <slot />
        </div>
        <slot name="outside" />
      </div>
    </NwMask>
  </div>
</template>

<style lang="less" scoped>
  .d {
    height: calc();
  }
</style>
