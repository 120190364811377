import gsap from 'gsap'
import type { Ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import type { AnimeParams } from 'animejs'
import anime from 'animejs'

export const useAnimationInOut = (el: Ref<HTMLElement>) => {
  onMounted(() => {
    gsap.from(el.value, {
      duration: 1,
      x: '-400',
      autoAlpha: 0,
      ease: 'ease',
    })
  })

  onBeforeRouteLeave((_, __, next) => {
    gsap.to(el.value, {
      duration: 1,
      x: '+400',
      autoAlpha: 0,
      ease: 'ease',
      onComplete: () => {
        next()
      },
    })
  })
}

function translateY(classList: string | string[], duration = 600, y: string | string[] = ['100%', '0'], complete?: Function) {
  anime({
    targets: classList,
    duration,
    translateY: y,
    easing: 'easeInOutQuad',
    complete: () => {
      complete && complete()
    },
  })
}
function translateX(classList: string | string[], duration = 600, x: string | string[] = ['100%', '0'], complete?: Function) {
  anime({
    targets: classList,
    duration,
    translateX: x,
    easing: 'easeInOutQuad',
    complete: () => {
      complete && complete()
    },
  })
}
function scale(classList: string | string[] | any, duration = 600, scale: number | number[] = [0.5, 1], complete?: Function) {
  anime({
    targets: classList,
    duration,
    scale,
    easing: 'easeInBack',
    complete: () => {
      complete && complete()
    },
  })
}

function doAnima(
  classList: string | string[] | any,
  duration = 600,
  attribute: AnimeParams & { translateX?: any; translateY?: any; opacity?: any },
  complete?: Function,
) {
  return anime({
    targets: classList,
    duration,
    easing: 'easeOutQuad',
    complete: () => {
      complete && complete()
    },
    ...attribute,
  })
}

export const useAnimation = () => {
  return { translateX, translateY, doAnima, scale }
}
