export enum ApiEnum {
  /**
  xero重定向回来后，通过此接口传用户授权信息
  @request: AccountingAllowAccessRequest
  @response: GenericResponseAccountingAuthRecordInfo
  */
  AccountingAuthRecordAllowAccess = '/accountingAuthRecord/allowAccess',

  /**
  触发同步数据
  @request: ApplySyncAccountingDataRequest
  @response: BaseResponse
  */
  AccountingAuthRecordApplySyncDate = '/accountingAuthRecord/applySyncDate',

  /**
  用户在pinvo再次确认授权
  @request: AccountingAuthConfirmRequest
  @response: GenericResponseAccountingAuthRecordInfo
  */
  AccountingAuthRecordConfirmAuth = '/accountingAuthRecord/confirmAuth',

  /**
  获取会计软件授权链接
  @request: GetAccountingAuthUrlRequest
  @response: GenericResponseAccountingAuthRecordInfo
  */
  AccountingAuthRecordGetAuthUrl = '/accountingAuthRecord/getAuthUrl',

  /**
  查询已授权的授权记录
  @request: QueryAccountingAuthedRecordRequest
  @response: GenericResponseAccountingAuthRecordInfo
  */
  AccountingAuthRecordQueryAuthedRecord = '/accountingAuthRecord/queryAuthedRecord',

  /**
  切换同步的开关
  @request: SwitchAccountingSyncRequest
  @response: GenericResponseAccountingAuthRecordInfo
  */
  AccountingAuthRecordSwitchSync = '/accountingAuthRecord/switchSync',

  /**
  undefined
  @request: undefined
  @response: BaseResponse
  */
  NotifyAliyunMnsEmailOpen = '/notify/aliyun/mns/emailOpen',

  /**
  通过服务端RSA密钥KEY，获取服务端AES密钥值
  @request: AesKeyFetchRequest
  @response: GenericResponseApiDataEncryptConfigRecordInfo
  */
  ApiDataEncryptFetchServerAesKey = '/apiDataEncrypt/fetchServerAesKey',

  /**
  获取服务端RSA公钥值
  @request: undefined
  @response: GenericResponseApiDataEncryptConfigRecordInfo
  */
  ApiDataEncryptFetchServerRsaPublicKey = '/apiDataEncrypt/fetchServerRsaPublicKey',

  /**
  通过AesKeyId直接获取服务端AES密钥值
  @request: AesKeyQueryRequest
  @response: GenericResponseApiDataEncryptConfigRecordInfo
  */
  ApiDataEncryptQueryServerAesKeyByAesKeyId = '/apiDataEncrypt/queryServerAesKeyByAesKeyId',

  /**
  获取媒体临时上传URL
  @request: MediumUploadUrlFetchRequest
  @response: GenericResponseMediumUploadUrlInfo
  */
  AwsFetchMediumUploadUrl = '/aws/fetchMediumUploadUrl',

  /**
  undefined
  @request: undefined
  @response: BaseResponse
  */
  NotifyAwsSnsEmailOpen = '/notify/aws/sns/emailOpen',

  /**
  绑定银行账户和科目
  @request: BindBankAccountsAndCategoriesRequest
  @response: GenericResponseBankAccountsAndCategoriesInfo
  */
  BankAccountBindBankAccountsAndCategories = '/bankAccount/bindBankAccountsAndCategories',

  /**
  管理银行账户
  @request: ManageReceivingBankAccountRequest
  @response: GenericResponseBankAccountInfo
  */
  BankAccountManageReceivingBankAccount = '/bankAccount/manageReceivingBankAccount',

  /**
  查询银行账户信息
  @request: QueryBankAccountInfoRequest
  @response: GenericResponseListBankAccountInfo
  */
  BankAccountQueryBankAccounts = '/bankAccount/queryBankAccounts',

  /**
  查询所有银行账户信息和科目信息，仅用于管理两类信息绑定关系的页面使用。
  @request: QueryBankAccountsAndCategoriesRequest
  @response: GenericResponseBankAccountsAndCategoriesInfo
  */
  BankAccountQueryBankAccountsAndCategories = '/bankAccount/queryBankAccountsAndCategories',

  /**
  删除收款银行账户
  @request: RemoveReceivingBankAccountRequest
  @response: BaseResponse
  */
  BankAccountRemoveReceivingBankAccount = '/bankAccount/removeReceivingBankAccount',

  /**
  AP创建账单
  @request: BillCreateRequest
  @response: GenericResponseBillAndPaymentInfo
  */
  BillCreate = '/bill/create',

  /**
  批量删除账单
  @request: BillsBatchOperateRequest
  @response: BaseResponse
  */
  BillDelete = '/bill/delete',

  /**
  批量账单标识为已支付
  @request: BillsBatchOperateRequest
  @response: BaseResponse
  */
  BillMarkAsPaid = '/bill/markAsPaid',

  /**
  批量账单标识为未支付
  @request: BillsBatchOperateRequest
  @response: BaseResponse
  */
  BillMarkAsUnpaid = '/bill/markAsUnpaid',

  /**
  AP修改账单
  @request: BillModifyRequest
  @response: GenericResponseBillAndPaymentInfo
  */
  BillModify = '/bill/modify',

  /**
  分页查询账单
  @request: PageQueryBillRequest
  @response: GenericResponsePageQueryDataResultListBillAndPaymentInfo
  */
  BillPageQueryBills = '/bill/pageQueryBills',

  /**
  查询账单信息
  @request: QueryBillRequest
  @response: GenericResponseBillAndPaymentInfo
  */
  BillQueryBillInfo = '/bill/queryBillInfo',

  /**
  查询账单的timeline
  @request: BillOperationRecordQueryRequest
  @response: GenericResponseListOperationRecordInfo
  */
  BillQueryOperationRecords = '/bill/queryOperationRecords',

  /**
  查询账单汇总信息
  @request: BillStatisticQueryRequest
  @response: GenericResponseBillStatisticInfo
  */
  BillQueryStatistic = '/bill/queryStatistic',

  /**
  查询银存科目
  @request: BankAccountCategoryQueryRequest
  @response: GenericResponseListCategoryInfo
  */
  CategoryQueryAllBankAccountCategories = '/category/queryAllBankAccountCategories',

  /**
  使用科目名称查询科目，包含已归档科目
  @request: QueryCategoryRequest
  @response: GenericResponseCategoryInfo
  */
  CategoryQueryCategoryByName = '/category/queryCategoryByName',

  /**
  创建联系人
  @request: ContactCreateRequest
  @response: GenericResponseContactInfo
  */
  ContactCreate = '/contact/create',

  /**
  删除联系人
  @request: ContactDeleteRequest
  @response: BaseResponse
  */
  ContactDelete = '/contact/delete',

  /**
  修改联系人
  @request: ContactModifyRequest
  @response: GenericResponseContactInfo
  */
  ContactModify = '/contact/modify',

  /**
  分页查询联系人列表
  @request: ContactPageQueryRequest
  @response: GenericResponsePageQueryDataResultListContactInfo
  */
  ContactPageQueryContacts = '/contact/pageQueryContacts',

  /**
  查询联系人
  @request: ContactQueryRequest
  @response: GenericResponseContactInfo
  */
  ContactQueryContact = '/contact/queryContact',

  /**
  模糊查询联系人
  @request: ContactSearchRequest
  @response: GenericResponseListContactInfo
  */
  ContactSearch = '/contact/search',

  /**
  hello retry
  @request: undefined
  @response: GenericResponseString
  */
  DemoHelloRetryTask = '/demo/helloRetryTask',

  /**
  hello world
  @request: undefined
  @response: GenericResponseString
  */
  DemoHelloWorld = '/demo/helloWorld',

  /**
  getEnumsDemo
  @request: undefined
  @response: GenericResponseAllEnumsInfo
  */
  ForTestGetEnumsDemo = '/forTest/getEnumsDemo',

  /**
  测试OpenAI结合私有数据的方案
  @request: undefined
  @response: string
  */
  OrgInfoGetOrgsInfo = '/orgInfo/getOrgsInfo',

  /**
  给指定邮箱发送邮件
  @request: EmailSendRequest
  @response: BaseResponse
  */
  EmailSend = '/email/send',

  /**
  undefined
  @request: undefined
  @response: string
  */
  NotifyGocardlessNotification = '/notify/gocardless/notification',

  /**
  信任登录
  @request: UserTrustLoginRequest
  @response: GenericResponseLoginInfo
  */
  InnerLoginTrustLogin = '/inner/login/trustLogin',

  /**
  查询或快速创建组织和用户
  @request: OrgAndUserCreateRequest
  @response: GenericResponseOrgAndUserInfo
  */
  InnerOrgQueryOrCreateOrgAndUser = '/inner/org/queryOrCreateOrgAndUser',

  /**
  撤销收款账单
  @request: InvoiceCancelRequest
  @response: BaseResponse
  */
  InvoiceCancelInvoice = '/invoice/cancelInvoice',

  /**
  创建收款单
  @request: InvoiceCreateRequest
  @response: GenericResponseInvoiceInfo
  */
  InvoiceCreateInvoice = '/invoice/createInvoice',

  /**
  删除收款账单
  @request: InvoiceDeleteRequest
  @response: BaseResponse
  */
  InvoiceDeleteInvoice = '/invoice/deleteInvoice',

  /**
  模糊查询交付信息
  @request: InvoiceBizItemFuzzyQueryRequest
  @response: GenericResponseListInvoiceBizItemInfo
  */
  InvoiceFuzzyQueryInvoiceBizItem = '/invoice/fuzzyQueryInvoiceBizItem',

  /**
  生成invoiceNo
  @request: InvoiceNoGenerateRequest
  @response: GenericResponseGenerateInvoiceNoInfo
  */
  InvoiceGenerateInvoiceNo = '/invoice/generateInvoiceNo',

  /**
  手动设置收款账单到Paid状态
  @request: InvoiceMarkRequest
  @response: BaseResponse
  */
  InvoiceMarkAsPaid = '/invoice/markAsPaid',

  /**
  手动设置收款账单到Unpaid状态
  @request: InvoiceMarkRequest
  @response: BaseResponse
  */
  InvoiceMarkAsUnpaid = '/invoice/markAsUnpaid',

  /**
  修改收款单
  @request: InvoiceModifyRequest
  @response: GenericResponseInvoiceInfo
  */
  InvoiceModifyInvoice = '/invoice/modifyInvoice',

  /**
  分页查询收款单
  @request: InvoicePageQueryRequest
  @response: GenericResponsePageQueryDataResultListInvoiceInfo
  */
  InvoicePageQueryInvoices = '/invoice/pageQueryInvoices',

  /**
  查询收款单
  @request: InvoiceQueryRequest
  @response: GenericResponseInvoiceInfo
  */
  InvoiceQueryInvoice = '/invoice/queryInvoice',

  /**
  查询收款账单统计数据
  @request: InvoiceStatisticQueryRequest
  @response: GenericResponseInvoiceStatisticInfo
  */
  InvoiceQueryStatistic = '/invoice/queryStatistic',

  /**
  发送收款单
  @request: InvoiceSendRequest
  @response: BaseResponse
  */
  InvoiceSendInvoice = '/invoice/sendInvoice',

  /**
  Google SSO登录
  @request: UserLoginWithGoogleRequest
  @response: GenericResponseLoginInfo
  */
  LoginLoginWithGoogle = '/login/loginWithGoogle',

  /**
  账密登录
  @request: UserLoginWithPwdRequest
  @response: GenericResponseLoginInfo
  */
  LoginLoginWithPwd = '/login/loginWithPwd',

  /**
  登出服务
  @request: undefined
  @response: BaseResponse
  */
  Logout = '/logout',

  /**
  通过用户上传的文件摘要信息，查询对应的文件对象
  @request: FileQueryRequest
  @response: GenericResponseFileInfo
  */
  MediumQueryFile = '/medium/queryFile',

  /**
  通过用户上传的图片摘要信息，查询对应的图片对象
  @request: ImageQueryRequest
  @response: GenericResponseImageInfo
  */
  MediumQueryImage = '/medium/queryImage',

  /**
  使用已通过客户端上传到文件存储上的文件链接，来注册文件对象
  @request: FileRegisterRequest
  @response: GenericResponseFileInfo
  */
  MediumRegisterFile = '/medium/registerFile',

  /**
  使用已通过客户端上传到文件存储上的源图片链接，来注册图片对象
  @request: ImageRegisterRequest
  @response: GenericResponseImageInfo
  */
  MediumRegisterImage = '/medium/registerImage',

  /**
  异步批量OCR识别账单申请
  @request: InvoiceOcrApplyRequest
  @response: BaseResponse
  */
  OcrApplyParseInvoice = '/ocr/applyParseInvoice',

  /**
  同步OCR识别账单
  @request: InvoiceOcrRequest
  @response: GenericResponseInvoiceOcrDataInfo
  */
  OcrParseInvoice = '/ocr/parseInvoice',

  /**
  查询账单OCR识别结果
  @request: InvoiceOcrQueryRequest
  @response: GenericResponseInvoiceOcrDataInfo
  */
  OcrQueryInvoiceParseResult = '/ocr/queryInvoiceParseResult',

  /**
  通过操作对象查询对应的操作记录
  @request: OperationRecordQueryRequest
  @response: GenericResponseListOperationRecordInfo
  */
  OperationRecordQueryByOperateTarget = '/operationRecord/queryByOperateTarget',

  /**
  创建组织
  @request: OrgCreateRequest
  @response: GenericResponseOrgInfo
  */
  OrgCreate = '/org/create',

  /**
  修改组织信息
  @request: OrgModifyRequest
  @response: GenericResponseOrgInfo
  */
  OrgModify = '/org/modify',

  /**
  查询创建的组织信息
  @request: undefined
  @response: GenericResponseOrgInfo
  */
  OrgQueryOwnedOrg = '/org/queryOwnedOrg',

  /**
  给邮箱发送OTP
  @request: SendEmailOtpRequest
  @response: BaseResponse
  */
  OtpSendEmail = '/otp/send/email',

  /**
  创建Payment link
  @request: CreatePaymentLinkRequest
  @response: GenericResponsePaymentLinkInfo
  */
  PaymentLinkCreatePaymentLink = '/paymentLink/createPaymentLink',

  /**
  删除Payment link
  @request: DeletePaymentLinkRequest
  @response: GenericResponsePaymentLinkInfo
  */
  PaymentLinkDeletePaymentLink = '/paymentLink/deletePaymentLink',

  /**
  分页查询PaymentLink订单
  @request: PageQueryPaymentLinkOrderRequest
  @response: GenericResponsePageQueryDataResultListPaymentLinkOrderInfo
  */
  PaymentLinkPageQueryPaymentLinkOrders = '/paymentLink/pageQueryPaymentLinkOrders',

  /**
  分页查询Payment link
  @request: PageQueryPaymentLinkRequest
  @response: GenericResponsePageQueryDataResultListPaymentLinkViewInfo
  */
  PaymentLinkPageQueryPaymentLinks = '/paymentLink/pageQueryPaymentLinks',

  /**
  用账单id查询
  @request: InvoiceQueryByBillRequest
  @response: GenericResponseBillAndPaymentInfo
  */
  PaymentRequestQueryInvoiceInfo = '/paymentRequest/queryInvoiceInfo',

  /**
  保存潜在客户信息
  @request: PotentialCustomerInfoSaveRequest
  @response: void
  */
  PotentialCustomerSaveInfo = '/potentialCustomer/saveInfo',

  /**
  短链重定向到长链
  @request: undefined
  @response: void
  */

  /**
  查询销售的税率，用于invoice
  @request: QueryTaxRatesRequest
  @response: GenericResponseListTaxRateInfo
  */
  TaxRateQuerySaleTaxRates = '/taxRate/querySaleTaxRates',

  /**
  通过英国公司注册号查询英国公司信息
  @request: UkCompanyQueryRequest
  @response: GenericResponseUkCompanyInfo
  */
  UkCompanyQueryByNo = '/ukCompany/queryByNo',

  /**
  通过英国公司名称搜索英国公司信息列表
  @request: UkCompanyQueryRequest
  @response: GenericResponseListUkCompanyInfo
  */
  UkCompanySearchByName = '/ukCompany/searchByName',

  /**
  校验英国VAT NO的有效性
  @request: UkOrgVATNoCheckRequest
  @response: GenericResponseBoolean
  */
  UkOrgCheckVATNo = '/ukOrg/checkVATNo',

  /**
  重置密码申请服务
  @request: PasswordResetApplyRequest
  @response: BaseResponse
  */
  UserApplyResetPassword = '/user/applyResetPassword',

  /**
  修改密码服务
  @request: PasswordChangeRequest
  @response: BaseResponse
  */
  UserChangePassword = '/user/changePassword',

  /**
  修改用户信息
  @request: UserModifyRequest
  @response: GenericResponseUserInfo
  */
  UserModify = '/user/modify',

  /**
  用户自身信息查询
  @request: undefined
  @response: GenericResponseUserInfo
  */
  UserQuery = '/user/query',

  /**
  用户账密注册并登录
  @request: UserRegisterRequest
  @response: GenericResponseLoginInfo
  */
  UserRegisterWithPwdAndLogin = '/user/registerWithPwdAndLogin',

  /**
  重置密码服务
  @request: PasswordResetRequest
  @response: BaseResponse
  */
  UserResetPassword = '/user/resetPassword',

  /**
  验证邮箱服务
  @request: EmailVerifyRequest
  @response: BaseResponse
  */
  UserVerifyEmail = '/user/verifyEmail',
}
