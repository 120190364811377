<script lang="ts" setup>
import { isFunction, uniqueId } from 'lodash-es'
import type { Type } from 'django-naive-ui/es/button/src/interface'
import type { ButtonProps } from 'django-naive-ui'
import { preventDefault } from '~/utils/dom'
import { createNamespace } from '~/utils/bem'
import { sleep } from '~/utils'

interface IProps {
  type?: Type
  throttle?: 'debounce' | 'throttle'
  loading?: boolean
  loadingDuration?: number
  color?: string
  disabled?: boolean
  throttleTime?: number
  autoAsync?: boolean
  onClick?: any
  stopPropagation?: boolean
  icon?: string
  size?: string
  quaternary?: boolean
  iconClass?: string
}

const props = withDefaults(defineProps<IProps>(), {
})
const attrs = useAttrs()
const [name] = createNamespace('button')

type ButtonThemeOverrides = NonNullable<ButtonProps['themeOverrides']>

const buttonThemeOverrides: ButtonThemeOverrides = {
  paddingSmall: props.icon ? '0.8rem' : undefined,
  paddingMedium: props.icon ? '1rem' : undefined,
  iconSizeSmall: '2.4rem',
  iconSizeMedium: '3rem',
}

const _loading = ref(false)

const loading = computed(() => {
  return props.loading || _loading.value
})

const getListeners = computed(() => {
  const { throttle = 'throttle', throttleTime = 200, autoAsync } = props
  // 是否开启节流防抖
  const throttleType = throttle?.toLowerCase()
  const isDebounce = throttleType === 'debounce'
  const openThrottle = ['throttle', 'debounce'].includes(throttleType) && throttleTime > 0
  const on: {
    onClick?: Fn
  } = {}

  if (props.onClick && isFunction(props.onClick)) {
    let clickHandler = props.onClick
    // 自动添加loading状态
    if (autoAsync) {
      clickHandler = async (event: any) => {
        _loading.value = true
        try {
          await (props.onClick as Function)(event)
          await sleep(props.loadingDuration ? props.loadingDuration : 200)
          _loading.value = false
        }
        catch (error) {
          _loading.value = false
          throw error
        }
      }
    }

    if (openThrottle) {
      const throttledFn = isDebounce ? useDebounceFn(clickHandler, throttleTime) : useThrottleFn(clickHandler as any, throttleTime!)
      on.onClick = (event) => {
        // 外层是.stop阻止冒泡时，因为节流会导致方法间隔执行导致无法阻止冒泡
        preventDefault(event, props.stopPropagation)
        throttledFn(event)
      }
    }
  }

  return {
    ...attrs,
    ...on,
  }
})

const getBindValue = computed((): any => {
  return { ...props, ...unref(getListeners), loading: loading.value }
})
</script>

<template>
  <NButton
    v-bind="getBindValue" :type="type" :color="color" :disabled="disabled" :class="[name]"
    :theme-overrides="buttonThemeOverrides"
    :quaternary="quaternary || !!icon"
    :focusable="false"
    class="rounded-0.8rem"
    :size="size || (icon ? 'tiny' : 'medium')"
  >
    <template v-for="item in Object.keys($slots)" #[item]="data">
      <slot v-bind="{ ...data, key: uniqueId('btn_') }" :name="item" />
    </template>
    <template v-if="!!icon" #icon>
      <NwIcon :name="icon!" class="text-neutral-900" :class="iconClass" />
    </template>
  </NButton>
</template>

<style lang="less" scoped>
  .pinvo-button {
    // ::v-deep(.n-button__icon) {
    //   font-size: v-bind(iconSize);
    //   height: v-bind(iconSize);
    //   width: v-bind(iconSize);
    //   .n-icon-slot {
    //     height: v-bind(iconSize);
    //     width: v-bind(iconSize);
    //   }
    // }
  }
</style>
