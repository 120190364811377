<script lang="ts" setup>
import { isFunction } from 'lodash-es'
import { createNamespace } from '~/utils/bem'

interface IProps {
  show: boolean
  hiddenClose?: boolean
  className?: string
  width?: string
  adaptiveWidth?: boolean
  maskable?: boolean
  onConfirm?: any
  onCancel?: any
  confirmText?: string
  cancelText?: string
  showCancel?: boolean
  type?: 'error' | 'success' | 'loading'
  title?: string
  content?: string
}
const props = withDefaults(defineProps<IProps>(), {
  width: smallerMD.value ? '33.6rem' : '43.6rem',
  showCancel: true,
})

const emits = defineEmits(['update:show', 'beforeOpen', 'beforeClose', 'afterClose', 'afterOpen', 'close', 'confirm', 'cancel'])
const [name] = createNamespace('dialog')
const { t } = useI18n()

const iconMap = {
  error: {
    icon: 'warn-circle',
    className: 'text-danger',
  },
  success: {
    icon: 'finish-circle',
    className: 'text-success',
  },
  loading: {
    icon: 'loading',
    className: 'loading',
  },
}

const isLoading = computed(() => {
  return props.type === 'loading'
})

const close = () => {
  emits('update:show', false)
  emits('close')
}

const onCancelClick = async () => {
  if (isFunction(props.onCancel))
    await props.onCancel()

  close()
}

const onConfirmClick = async () => {
  if (isFunction(props.onConfirm))
    await props.onConfirm()

  close()
}
</script>

<template>
  <div :class="name">
    <NwPopup
      :show="show"
      position="center"
      content-class-name="rounded-1.2rem"
      class-name="relative"
      :maskable="maskable"
      @update:show="$emit('update:show', $event)"
      @before-open="$emit('beforeOpen', $event)"
      @before-close="$emit('beforeClose', $event)"
      @after-close="$emit('afterClose', $event)"
      @after-open="$emit('afterOpen', $event)"
    >
      <!-- 关闭按钮 -->
      <div class="flex justify-end pr-2rem pt-2rem">
        <NwIcon v-if="!hiddenClose" name="close" class="text-2.4rem cursor-pointer " @click="close" />
      </div>
      <div class="pt-1.6rem px-4rem mb-4rem flex flex-col items-center" :style="{ width: !adaptiveWidth ? width : '' }" :class="className">
        <NwIcon v-if="type" :name="iconMap[type].icon" class="text-4.8rem mb-2.4rem" :class="[iconMap[type].className]" />
        <p v-if="title" class=" text-hlg font-semibold mb-1.6rem whitespace-pre-wrap text-center">
          {{ title }}
        </p>
        <div v-if="content " class="text-center whitespace-pre-wrap ">
          {{ content }}
          <!-- <div v-for="(item, i) in content" :key="`confirm-content-${i}`">
            {{ item }}
          </div> -->
        </div>
        <slot />
      </div>
      <div v-if="!isLoading" class="flex flex-col items-center  px-4rem pb-4rem">
        <NwButton auto-async type="primary" class="" @click="onConfirmClick">
          {{ confirmText || t('button.yes') }}
        </NwButton>
        <div v-if="showCancel" class="mt-1.2rem text-lg leading-4.4rem w-20rem font-semibold text-center cursor-pointer" @click="onCancelClick">
          {{ cancelText || t('button.cancel') }}
        </div>
      </div>

      <template #outside />
    </NwPopup>
  </div>
</template>

  <style lang="less" scoped>
    .pinvo-dialog {
      .loading {
        // font-size: 6.8rem;
        animation: spin 2s linear infinite;
      }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  </style>
