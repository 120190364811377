import type { MessageOptions } from 'django-naive-ui'
import type { NwConfirmDialogOption } from '~/types'

const nwMessageIns = ref<{ create: (content: string, options?: MessageOptions, placement?: string) => {} }>()
const nwConfirmDialogIns = ref<{ create: (options?: NwConfirmDialogOption) => {}; close: () => {} }>()

export const useNwMessage = () => {
  const create = (content: string, options?: MessageOptions, placement?: string) => {
    if (nwMessageIns.value)
      nwMessageIns.value.create(content, options, placement)
    else
      consola.warn('nwMessageIns is null')
  }

  const createConfirm = (option: Partial<NwConfirmDialogOption>) => {
    nwConfirmDialogIns.value?.create({ show: true, ...option })
    return nwConfirmDialogIns
  }

  return { create, createConfirm }
}

export const setupNwMessageIns = (ins: any) => {
  nwMessageIns.value = ins
}

export const setupNwConfirmDialogIns = (ins: any) => {
  nwConfirmDialogIns.value = ins
}
