export const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'GBP',
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  'en-UK': {
    currency: {
      style: 'currency', currency: 'GBP',
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },

  'en-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
    },

  },

  'fr': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },

  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },

}
