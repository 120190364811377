export enum QueryKeyEnum {
  // serverAesKeyId
  NW_K = 'nw_k',
  Get_Paid_Type = 'gp_type',
  Target_Id = 't_id',
  Target_Type = 't_type',
  Pay_Type = 'p_type',
  O_Id = 'o_id',
  R_Id = 'r_id',
  Service_Provide = 'sp',
  Login_State = 'login_state',
  Register_Email = 'r_email',
  Token = 'token',
  Setting_Type = 'setting_type',
  C_Id = 'c_id',
  C_Type = 'c_type',
  Action_Type = 'action_type',
  Invoice_Id = 'invoice_id',
}

/**
 * 前端区分是否登录状态下的付款类型
 */
export enum PayChannelTypeSecurityCodeEnum {
  Authorized = 'authorized',
  Unauthorized = 'unauthorized',
}
