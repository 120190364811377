import { acceptHMRUpdate, defineStore } from 'pinia'
import type { UserInfo } from '~/apis/swagger/apiModel'
import { userLogoutApi, userQueryApi } from '~/apis/user'
import { CacheKey } from '~/enums/cache'

export const useUserStore = defineStore('user', () => {
  const token = getLocal<string>(CacheKey.TOKEN, '')
  const userInfo = getLocal<UserInfo>(CacheKey.USER_INFO)
  const setToken = (tk: string) => {
    token.value = tk
    manualSettingLocal()
  }

  const setUserInfo = (u: UserInfo) => {
    userInfo.value = u
    Sentry.setUser({
      username: (u.firstName || '') + (u.lastName || ''),
      id: u.userId,
      email: u.email,
    })
  }

  const clearState = () => {
    token.value = ''
    userInfo.value = {} as any
  }

  const logout = async () => {
    await userLogoutApi()
  }

  const fetchUpdateUserInfo = async () => {
    const res = await userQueryApi()
    setUserInfo(res)
  }

  return {
    logout,
    setToken,
    clearState,
    setUserInfo,
    fetchUpdateUserInfo,
    userInfo,
    token,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
