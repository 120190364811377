import type { RouteRecordRaw } from 'vue-router'
import { enterValidateGuard } from './otpGuard'
import { enterOrgCreateGuard } from './orgGuard'
import { RoutePath } from '~/enums/path'

export const setupEnterGuard = (routes: RouteRecordRaw[]) => {
  const routeMap: Record<string, RouteRecordRaw> = {}
  routes.forEach((e) => {
    routeMap[e.path] = e.children![0]
  })
  routeMap[RoutePath.Validate].beforeEnter = enterValidateGuard
  routeMap[RoutePath.OrgCreate].beforeEnter = enterOrgCreateGuard
}
