export const setDomStyleProperty = (key: string, v: string) => {
  document.documentElement.style.setProperty(key, v)
}

export const getCssUnitToNumber = (val: string, unit = 'px') => {
  const res = Number(val.replace(unit, ''))
  if (isNaN(res))
    return 0

  return res
}

export const stopPropagation = (event: Event) => event.stopPropagation()

export function preventDefault(event: Event, isStopPropagation?: boolean) {
  /* istanbul ignore else */
  if (typeof event.cancelable !== 'boolean' || event.cancelable)
    event.preventDefault()

  if (isStopPropagation)
    stopPropagation(event)
}

export const getCurrFullPath = () => {
  return `${window.location.pathname}${window.location.search}`
}

export function getQueryByUrl(url: string, options: { decode?: boolean; encode?: boolean } = {}) {
  if (!url)
    return {}
  const index = url.indexOf('?')
  const query = url.substring(index + 1)
  const vars = query.split('&')
  const params: Record<string, any> = {}
  for (let i = 0; i < vars.length; i++) {
    const start = vars[i].indexOf('=')
    if (start >= 0) {
      const k = vars[i].substring(0, start)
      let v = vars[i].substring(start + 1)
      if (options.encode)
        v = encodeURIComponent(v)

      if (options.decode)
        v = decodeURIComponent(v)

      params[k] = v
    }
  }
  return params
}

export function stringifyParams(obj: any, options?: { encode?: boolean }) {
  let parameters = ''
  for (const key in obj)
    parameters += `${key}=${options?.encode ? encodeURIComponent(obj[key]) : obj[key]}&`

  parameters = parameters.replace(/&$/, '')
  return parameters
}

export function getRootPath() {
  const publicPath = globSetting.publicPath
  return `${location.protocol}//${location.host}${(publicPath === '/' || !publicPath) ? '' : publicPath}`
}

/**
 * 设置页面标题
 * @param {*} title  :页面标题
 */
export function setDocumentTitle(title: string) {
  document.title = title
  const ua = navigator.userAgent
  const regex = /\bMicroMessenger\/([\d.]+)/
  // 兼容
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(() => {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}
