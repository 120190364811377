import { getNiceAxiosInstance } from 'nice-axios'
import axios, { type AxiosResponse } from 'axios'
import { createHttp } from '~/net'

createHttp()

// export. second
export const http = getNiceAxiosInstance()

const pureHttp = axios.create({
})

pureHttp.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  },
)

pureHttp.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.status === 200)
      return response

    throw new Error(response.status.toString())
  },
  (error) => {
    return Promise.reject(error)
  },
)

export { pureHttp }
