export enum CurrencyCodeEnum {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
  CNY = 'CNY',
}

export const CurrencySymbol = {
  [CurrencyCodeEnum.GBP]: '£',
  [CurrencyCodeEnum.USD]: '$',
  [CurrencyCodeEnum.EUR]: '€',
  [CurrencyCodeEnum.CNY]: '¥',
} as Record<string, string>

export const CurrencyOptions = Object.values(CurrencyCodeEnum).map(currency => ({
  label: `${currency} (${CurrencySymbol[currency]})`,
  value: currency,
}))
